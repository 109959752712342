import React from 'react'
import Select from '@material-ui/core/Select'
import { MenuItem, InputLabel } from '@material-ui/core'

export function SelectField({ options, emptyOption, label, ...props }) {
  return (
    <>
      {label && <InputLabel>{label}</InputLabel>}
      <Select {...props}>
        {emptyOption && (
          <MenuItem value="" key={-1}>
            {emptyOption}
          </MenuItem>
        )}
        {options.map((option, idx) => (
          <MenuItem value={option.value} key={idx}>
            {option.label || option.value}
          </MenuItem>
        ))}
      </Select>
    </>
  )
}
