export const placa = {
  get array() {
    return [/[A-Z]/i, /[A-Z]/i, /[A-Z]/i, '-', /\d/, /[A-Z\d]/i, /\d/, /\d/];
  },
  get regex() {
    return /[A-Z][A-Z][A-Z]-\d[A-Z\d]\d\d/;
  },
};

export const cpf = {
  get array() {
    return [
      /\d/,
      /\d/,
      /\d/,
      '.',
      /\d/,
      /\d/,
      /\d/,
      '.',
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
    ];
  },
  get regex() {
    return /\d\d\d.\d\d\d.\d\d\d-\d\d/;
  },
};

export const cnpj = {
  get array() {
    return [
      /\d/,
      /\d/,
      '.',
      /\d/,
      /\d/,
      /\d/,
      '.',
      /\d/,
      /\d/,
      /\d/,
      '/',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
    ];
  },
  get regex() {
    return /\d\d.\d\d\d.\d\d\d\/\d\d\d\d-\d\d/;
  },
};

export const telefoneCelular = {
  get array() {
    return [
      '(',
      /\d/,
      /\d/,
      ')',
      ' ',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ];
  },
  get regex() {
    return /\(\d\d\) \d\d\d\d\d-\d\d\d\d/;
  },
};

export const telefoneFixo = {
  get array() {
    return [
      '(',
      /\d/,
      /\d/,
      ')',
      ' ',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ];
  },
  get regex() {
    return /\(\d\d\) \d\d\d\d-\d\d\d\d/;
  },
};
