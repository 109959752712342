import React from 'react'
import PropTypes from 'prop-types'

export function ErrorMessage({ form, attr }) {
  const messages = (form.errors || {})[attr]
  const message = Array.isArray(messages) ? messages.join(', ') : messages

  return message ? (
    <span>
      <font color='red'>{message}</font>
    </span>
  ) : null
}

ErrorMessage.propTypes = {
  form: PropTypes.shape({ errors: PropTypes.object }).isRequired,
  attr: PropTypes.string.isRequired,
}

ErrorMessage.defaultProps = {
}

export default ErrorMessage
