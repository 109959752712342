import styled from 'styled-components'
import { Box } from '@material-ui/core'

export const Paginator = styled(Box)`
  padding:10px;

  display:flex;
  align-items:center;
  justify-content:${props => props.media === 'md' ? 'space-between' : 'flex-end'};

  font-size:13px;
`

export const PaginatorActions = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  div.action {
    margin-left:15px;

    .MuiSelect-select {
      font-size:13px;
    }
  }

  .paginator-menu {
    * {
      font-size:0.9rem;
    }
    &:focus {
      outline:none;
    }
  }
`