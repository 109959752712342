import styled from 'styled-components'
import theme from '../../theme'
import { Box } from '@material-ui/core'
import { darken } from 'polished'
import { AppBar, SwipeableDrawer as MuiDrawer, makeStyles } from '@material-ui/core'


export const useStyles = makeStyles({
  avatar: {
    color: '#8b7e7e',
    background: '#e3e3e3',
  },
  logoArea: {
    display: 'flex',
    justifyContent: 'center',
    flex: 1,
  },
  logo: {
    margin: '-15px',
    marginTop: '-9px',
    marginLeft: '3px'
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
})

export const StyledAppNavbar = styled(AppBar)`
  background-color: ${theme.palette.secondary.main};
  height:65px;
  display:flex;
  justify-content:center;
`

export const Drawer = styled(MuiDrawer)`
  .MuiPaper-root {
    background-color: ${theme.palette.secondary.main};
    * {
      color: ${theme.palette.secondary.light};
    } 
    .list {
      min-width: 300px;
      .name {
        text-align:center;
      }
      .item {
        height:60px;
        transition: 0.2s ease;
      }
      .item:hover,
      .active {
        cursor: pointer;
        transition: 0.2s ease;

        background-color: ${theme.palette.secondary.hover}; 
          * {
            color: ${theme.palette.secondary.light};
          }
        }
      .sub-item {
        height:50px;
      }
    }
  }
`

export const MenuArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin:0 15px; 
`

export const AvatarArea = styled(Box)`
  padding: ${theme.spacing(0.5)}px;
  padding-right: ${theme.spacing(1)}px;
  margin: ${theme.spacing(3)}px;

  display: flex;
  justify-content:center;
  align-items:center;
  
  background-color: ${darken(0.05, theme.palette.secondary.main)};
  border-radius: 22px;

  .MuiAvatar-root {
    background-color: #FFF;
    color: ${darken(0.05, theme.palette.secondary.main)};
  }

  ${{
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    }
  }}
`