import React from 'react'
import { Route, Switch, Redirect, useLocation } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'
import { Login, Home, Orders, OrderItems } from './pages'
import PageNotFound from './components/PageNotFound/index'
import { isAuthenticated } from './services/auth'

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(1)
  }
}))

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() ?
         <Component {...props} />
        : <Redirect to='/' />
    }
  />
)

export default function Routes() {

  const authenticated = isAuthenticated()

  const local = useLocation()
  const pathName = local.pathname
  const classes = useStyles()

  return (
    <>
      {
        authenticated && pathName === '/' 
        ? <Redirect to='/home'/> 
        : !authenticated && <Redirect to='/'/>
      }

      <div className={classes.container}>
        <Switch>
          <Route path="/" exact component={Login} />
          <PrivateRoute path="/home" exact component={Home} />
          <PrivateRoute path="/orders" exact component={Orders} />
          <PrivateRoute path="/orders/:id/items" exact component={OrderItems} />
          <Route path="*" component={PageNotFound} />
        </Switch>
      </div>
    </>
  )
}