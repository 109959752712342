import { format, parse, formatISO, parseISO, isValid } from 'date-fns';
import { conformToMask } from 'react-text-mask';
import {
  placa,
  cpf,
  cnpj,
  telefoneCelular,
  telefoneFixo,
} from '../consts/regexp';

import { unmask } from './masks';

const formatterISO = date => {
  if (date) {
    const splitDate = date.split('/')
    const newDate = new Date(
      parseInt(splitDate[2], 10),
      parseInt(splitDate[1], 10) - 1,
      parseInt(splitDate[0], 10))

    return formatISO(newDate)
  }
}

// const MS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
//   .reduce((acc, sf, idx) => {
//     acc[sf] = idx
//     return acc
//   }, {})

const MS = { 'Jan': 1, 'Feb': 2, 'Mar': 3, 'Apr': 4, 'May': 5, 'Jun': 6, 'Jul': 7, 'Aug': 8, 'Sep': 9, 'Oct': 10, 'Nov': 11, 'Dec': 12 }

const _toDayOnly = dateInIso => {
  const parsedDate = new Date(Date.parse(dateInIso)).toUTCString();

  // eslint-disable-next-line no-unused-vars
  const [_weekDay, day, _month, year] = parsedDate.split(" ");

  const monthIndex = MS[_month]
  const month = monthIndex < 10 ? '0' + monthIndex : monthIndex

  return `${day}/${month}/${year}`
}

const formats = value => ({
  toM2: () => {
    switch (typeof value) {
      case 'number':
        return value.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
      default:
        return value;
    }
  },
  toKg: () => {
    switch (typeof value) {
      case 'number':
        return `${value.toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
        })} kg`;
      case 'string':
        return `${value} kg`;
      default:
        return value;
    }
  },
  toDecimal: numDigits => {
    switch (typeof value) {
      case 'number':
        return `${value.toLocaleString('pt-BR', {
          minimumFractionDigits: numDigits,
        })}`;
      default:
        return value;
    }
  },
  toPallet: () => {
    switch (typeof value) {
      case 'number':
        return `${value.toLocaleString('pt-BR')} ${value > 1 ? 'Pallets' : 'Pallet'
          }`;
      default:
        return value;
    }
  },
  toCaixa: () => {
    switch (typeof value) {
      case 'number':
        return `${value.toLocaleString('pt-BR')} ${value > 1 ? 'Caixas' : 'Caixas'
          }`;
      default:
        return value;
    }
  },
  ISO: () => {
    switch (typeof value) {
      case 'string':
        return formatterISO(value);
      default:
        return value;
    }
  },
  // ISO ou yyyy-mm-dd -> dd/mm/yyyy
  toDayOnly: () => {
    switch (typeof value) {
      case 'string': {
        return _toDayOnly(value);
      }
      default:
        return value;
    }
  },
  toDayOnlyISO: () => {
    switch (typeof value) {
      case 'object': {
        if (!isValid(value)) return value;
        return format(value, 'yyyy-MM-dd');
      }
      default:
        return value;
    }
  },
  // yyyy-mm-dd -> ISO
  dateOnlyToISO: () => {
    switch (typeof value) {
      case 'string': {
        const parsedDate = parseISO(value);
        return parsedDate;
      }
      default:
        return value;
    }
  },
  // dd/mm/yyyy -> ISO
  localeDateOnlyToISO: () => {
    switch (typeof value) {
      case 'string': {
        const parsedDate = parse(value, 'dd/MM/yyyy', new Date());
        return parsedDate;
      }
      default:
        return value;
    }
  },
  toCpf: () => {
    switch (typeof value) {
      case 'string': {
        return conformToMask(value, cpf.array).conformedValue;
      }
      default:
        return value;
    }
  },
  toCNPJ: () => {
    switch (typeof value) {
      case 'string': {
        return conformToMask(value, cnpj.array).conformedValue;
      }
      default:
        return value;
    }
  },
  toCNPJOrCPF: () => {
    switch (typeof value) {
      case 'string': {
        if (value.length > 11)
          return conformToMask(value, cnpj.array).conformedValue;
        return conformToMask(value, cpf.array).conformedValue;
      }
      default:
        return value;
    }
  },
  toPlaca: () => {
    switch (typeof value) {
      case 'string': {
        return conformToMask(value, placa.array).conformedValue;
      }
      default:
        return value;
    }
  },
  toTelefone: () => {
    switch (typeof value) {
      case 'string': {
        if (unmask(value).length > 10)
          return conformToMask(value, telefoneCelular.array).conformedValue;

        return conformToMask(value, telefoneFixo.array).conformedValue;
      }
      default:
        return value;
    }
  },
});

export const formatter = value => formats(value);

export const toPalletECaixa = (pallets, caixas) =>
  `${pallets > 0 ? formatter(pallets).toPallet() : ''}${pallets > 0 && caixas > 0 ? ' ' : ''
  }${caixas > 0 ? formatter(caixas).toCaixa() : ''}`;

export default formatter;
