import { useState } from 'react'

export default function usePagination({
  initialPage = 0,  
  initialRecordsPerPage = 10,
} = {}) {
  
  const [currentPage, setCurrentPage] = useState(initialPage)
  const [recordsPerPage, setRecordsPerPage] = useState(initialRecordsPerPage)

  const firstPage = () => {
    setCurrentPage(0)
  }

  const changeRecordsPerPage = (perPage) => {
    setRecordsPerPage(perPage)
    setCurrentPage(0)
  }

  const calculateNewPaginatorData = (records) => {
    const startIndex = currentPage * recordsPerPage
    const totalPages = Math.ceil(records.length / recordsPerPage)
    const currentPageRecords = records.slice(
      startIndex,
      startIndex + recordsPerPage
    )

    return { totalPages, currentPageRecords }
  }

  return {
    currentPage,
    recordsPerPage,
    gotoPage: setCurrentPage,
    gotoFirstPage: firstPage,
    changeRecordsPerPage,
    calculateNewPaginatorData,
  }
}
