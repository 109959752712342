import { all, equals, lensPath, view, split } from 'ramda'
import clsx from 'clsx'
import { formatter } from '../../helpers'
import * as Consts from './consts'

const isAction = type => !!(Consts.actionTypes.includes(type))

const isFlag = type => !!(Consts.flagTypes.includes(type))

const isChip = type => !!(type === 'chip')

const isActionOrFlag = type => isAction(type) || isFlag(type)

const cellAlign = align => align || Consts.DEFAULT_CELL_ALIGN

const booleanToString = value => value?.toString()

const findByPath = (path, value, { formatter } = {}) => {
  if (!path) return '';
  const fieldLens = lensPath(split('.', path))

  const current = view(fieldLens, value)

  return typeof formatter === 'function' ? formatter(current) : current
}

const showColumn = (column, small = false) => {

  const smallColumn = small ? column.showSm : true

  return all(equals(true))([!column.onlyFilter, smallColumn])
}

const calculeColumnsValues = (columns, rows) =>
  columns
    .filter(e => e.total)
    .reduce((accumulator, { key }) => {
      const sumColumns = rows.reduce((acc, row) => {
        const column = parseFloat(row[key])

        return acc + column
      }, 0)

      accumulator[key] = {
        sum: parseFloat(sumColumns),
        avarage: parseFloat(sumColumns / rows.length)
      }

      return accumulator
    }, {})

const calculeTableDimensions = (small, format = 'px') => {

  const height = (window.innerHeight - (small ? Consts.DIF_SMALL_WINDOW : Consts.DIF_LARGE_WINDOW)) + format
  const width = 0 // TODO

  return [height, width]
}

const handleClasses = ({ type, callback, noWrap, classes }, styles) => {
  return clsx(
    {
      [styles.chipColumn]: isChip(type),
      [styles.actionColumn]: isAction(type),
      [styles.link]: !!(callback),
      [styles.noWrap]: noWrap,
    }, typeof classes === 'object'
    ? classes.join(' ')
    : ''
  )
}

const formatByType = (value, type) => {
  switch (type) {
    case 'date':
      return formatter(value).toDayOnly()
    case 'company':
      return formatter(value).toCNPJOrCPF()
    default:
      return value
  }
}

const filtersDescription = (filters = {}, columns) => Object.entries(filters).reduce((acc, [key, value], _, self) => {
  const column = columns?.find(({ key: column }) => column === key)

  if (!column) return acc

  const formattedValue = formatByType(value, column.type)

  const last = self[self.length - 2]?.[0]
  const SUFFIX = key === last ? '' : ','

  acc += `${column.label.toLowerCase()}: (${formattedValue?.trim()})${SUFFIX} `

  return acc
}, '')

export {
  isAction,
  isFlag,
  isActionOrFlag,
  booleanToString,
  cellAlign,
  findByPath,
  showColumn,
  calculeColumnsValues,
  calculeTableDimensions,
  handleClasses,
  filtersDescription
}