export const events = {
  confirmation: {
    open: "@GuacuPortalVendas/CONFIRMATION/open",
    close: "@GuacuPortalVendas/CONFIRMATION/close",
    confirm: "@GuacuPortalVendas/CONFIRMATION/confirm",
    cancel: "@GuacuPortalVendas/CONFIRMATION/cancel",
  },
};

export default events;
