import React from 'react'
import PropTypes from 'prop-types'

export function Logo({
      height,
      width,
      border
}) {

      const svgW = (width ?? 516 * 1000000) + 'pt'
      const svgH = (height ?? 272 * 1000000) + 'pt'

      return (
            <svg style={{
                  border: border ? '1px solid #000' : 'none',
                  margin: 'auto',
                  width: '95%',
            }}
                  version="1.0" xmlns="http://www.w3.org/2000/svg"
                  width={svgW} height={svgH} viewBox="0 0 516.000000 272.000000"
                  preserveAspectRatio="xMidYMid meet">
                  <g transform="translate(0.000000,272.000000) scale(0.100000,-0.100000)"
                        stroke="none">
                        <path fill="#d3411c" d="M2937 2697 c10 -12 83 -100 162 -195 l144 -172 229 0 c219 0 229 1
      216 18 -7 10 -80 98 -162 195 l-149 177 -229 0 -229 0 18 -23z" />
                        <path fill="#d3411c" d="M3133 2168 c-61 -73 -134 -161 -163 -195 l-52 -63 230 0 230 0 148
      177 c82 97 155 185 162 195 13 17 4 18 -215 18 l-229 0 -111 -132z"/>
                        <path fill="#d3411c" d="M3767 2266 c15 -18 89 -106 163 -195 l135 -161 228 0 c173 0 226 3
      221 12 -4 6 -77 94 -161 195 l-154 183 -230 0 -230 0 28 -34z"/>
                        <path fill="#d3411c" d="M2922 1882 c1 -5 75 -95 163 -200 l160 -191 228 -1 c214 0 227 1 218
      18 -6 9 -79 98 -164 197 l-153 180 -228 3 c-125 1 -226 -1 -224 -6z"/>
                        <path fill="#d3411c" d="M3920 1708 c-84 -101 -160 -191 -168 -200 -14 -17 -3 -18 215 -18
      l230 0 159 190 c87 105 161 195 162 200 2 6 -77 10 -221 10 l-225 0 -152 -182z"/>
                        <path fill="#d3411c" d="M3098 1288 c-84 -101 -159 -191 -167 -200 -13 -17 -1 -18 215 -18
      l229 0 168 200 168 200 -230 0 -230 0 -153 -182z"/>
                        <path d="M3557 663 c-4 -3 -7 -106 -7 -228 0 -199 2 -223 18 -237 23 -21 55
      -30 76 -22 24 9 19 24 -8 24 -53 0 -56 13 -56 251 0 200 -4 232 -23 212z"/>
                        <path d="M2910 422 l0 -239 33 0 c17 0 60 -2 94 -3 76 -3 106 14 134 73 38 85
      15 227 -43 258 -38 21 -114 16 -148 -9 -16 -12 -32 -22 -35 -22 -3 0 -5 41 -5
      90 0 73 -3 90 -15 90 -13 0 -15 -33 -15 -238z m217 43 c45 -51 42 -178 -7
      -227 -24 -23 -37 -28 -79 -28 -27 0 -61 4 -75 10 -26 9 -26 11 -26 113 0 115
      3 122 70 151 44 19 88 12 117 -19z"/>
                        <path d="M196 626 c-52 -20 -101 -69 -133 -135 -52 -106 -32 -260 38 -296 43
      -23 108 -18 157 10 45 26 48 23 32 -37 -16 -56 -51 -78 -122 -78 -32 0 -74 6
      -92 14 -33 14 -33 14 -50 -21 -9 -19 -14 -38 -11 -43 8 -13 99 -30 158 -30
      102 0 168 35 201 105 10 20 35 119 56 221 21 101 43 191 50 199 6 7 21 15 33
      17 15 2 23 13 28 35 3 18 4 35 1 38 -3 3 -29 5 -59 5 -82 0 -107 -36 -139
      -197 -16 -77 -22 -91 -59 -130 -34 -35 -48 -43 -78 -43 -30 0 -39 5 -52 30
      -31 60 -6 181 50 237 28 28 40 33 82 33 43 0 50 3 65 30 11 19 13 32 7 36 -23
      14 -125 14 -163 0z"/>
                        <path d="M1030 538 c-56 -16 -91 -48 -118 -106 -33 -72 -35 -169 -5 -210 40
      -55 125 -60 187 -11 l26 20 0 -25 c0 -22 4 -26 30 -26 17 0 30 1 30 3 0 2 16
      82 35 177 19 95 35 175 35 177 0 2 -24 2 -52 1 -29 -2 -71 0 -93 4 -22 4 -56
      2 -75 -4z m113 -67 c6 -6 4 -37 -4 -83 -11 -62 -18 -78 -50 -110 -42 -44 -91
      -52 -109 -19 -26 50 -3 150 46 199 30 30 39 34 71 29 20 -4 41 -11 46 -16z"/>
                        <path d="M1405 541 c-67 -17 -127 -74 -144 -138 -29 -103 -3 -190 63 -214 20
      -7 36 -19 36 -27 0 -8 7 -20 17 -27 28 -21 5 -38 -43 -32 -37 4 -44 1 -50 -15
      -9 -28 0 -33 66 -33 43 0 64 5 79 19 25 22 28 74 6 82 -25 10 -17 21 18 28 52
      10 61 19 47 47 -7 13 -14 25 -15 27 -2 2 -21 -2 -43 -9 -57 -17 -88 -6 -102
      35 -12 38 0 111 25 150 29 44 93 59 132 31 18 -12 27 -13 48 -3 32 15 29 37
      -10 59 -37 22 -91 30 -130 20z"/>
                        <path d="M580 536 c0 -2 -11 -58 -25 -126 -37 -184 -33 -212 33 -230 41 -11
      87 -2 128 25 23 15 24 14 24 -5 0 -18 5 -21 37 -18 l37 3 33 160 c41 206 42
      195 -6 195 l-38 0 -24 -122 c-23 -117 -26 -124 -58 -145 -34 -23 -95 -31 -106
      -14 -3 5 6 67 20 138 14 70 25 131 25 136 0 4 -18 7 -40 7 -22 0 -40 -2 -40
      -4z"/>
                        <path d="M1605 528 c-15 -44 -52 -271 -48 -293 11 -57 94 -75 171 -36 42 21
      42 21 42 1 0 -16 7 -20 35 -20 19 0 35 1 35 3 0 2 16 82 35 177 19 95 35 175
      35 177 0 2 -18 3 -40 3 -33 0 -41 -4 -45 -22 -7 -30 -45 -219 -45 -224 0 -2
      -18 -13 -40 -24 -47 -24 -88 -26 -97 -4 -5 13 23 177 43 252 6 20 3 22 -35 22
      -24 0 -43 -5 -46 -12z"/>
                        <path d="M2166 516 c-40 -15 -46 -21 -71 -68 -21 -38 -20 -160 1 -204 18 -38
      40 -53 94 -65 37 -8 116 6 126 23 13 21 -13 27 -53 13 -35 -12 -49 -12 -79 -2
      -41 13 -74 62 -74 109 l0 28 120 0 120 0 0 35 c0 43 -20 94 -46 117 -28 24
      -92 31 -138 14z m90 -26 c37 -14 53 -38 54 -77 l0 -33 -101 0 c-99 0 -100 0
      -94 23 13 45 31 70 60 83 37 16 47 17 81 4z"/>
                        <path d="M2495 513 c-16 -8 -38 -22 -47 -31 -17 -14 -18 -13 -18 11 0 20 -5
      27 -20 27 -20 0 -20 -5 -18 -167 3 -142 5 -168 18 -168 12 0 15 21 18 122 l3
      121 35 31 c47 42 86 45 117 9 14 -17 17 -41 17 -155 0 -123 2 -134 18 -131 15
      3 17 18 19 122 1 114 2 119 28 147 50 51 110 56 134 10 6 -11 11 -77 11 -150
      0 -110 2 -131 15 -131 13 0 15 22 15 140 0 153 -7 182 -51 200 -37 16 -75 6
      -121 -31 l-38 -30 -11 24 c-12 26 -48 47 -77 47 -9 -1 -30 -8 -47 -17z"/>
                        <path d="M3287 515 c-47 -16 -51 -20 -43 -40 5 -14 8 -14 28 -1 80 50 178 15
      178 -63 0 -15 -12 -20 -72 -26 -85 -8 -132 -25 -147 -53 -16 -30 -13 -94 4
      -119 33 -47 126 -47 185 -1 l25 20 3 -26 c2 -14 10 -26 18 -26 21 0 20 259 -1
      300 -24 46 -104 62 -178 35z m163 -205 c0 -48 -1 -50 -47 -77 -78 -46 -153
      -25 -153 42 0 52 57 81 163 84 l37 1 0 -50z"/>
                        <path d="M3727 515 c-47 -16 -51 -20 -43 -40 5 -14 8 -14 28 -1 80 50 178 15
      178 -63 0 -15 -12 -20 -72 -26 -85 -8 -132 -25 -147 -53 -16 -30 -13 -94 4
      -119 33 -47 126 -47 185 -1 l25 20 3 -26 c2 -14 10 -26 18 -26 21 0 20 259 -1
      300 -24 46 -104 62 -178 35z m163 -205 c0 -48 -1 -50 -47 -77 -78 -46 -153
      -25 -153 42 0 52 57 81 163 84 l37 1 0 -50z"/>
                        <path d="M4065 523 c-57 -14 -95 -83 -95 -173 0 -72 19 -122 54 -145 37 -24
      107 -23 151 4 l38 22 -5 -52 c-7 -68 -31 -89 -100 -89 -27 0 -59 5 -70 11 -26
      14 -49 -4 -30 -23 18 -18 136 -23 173 -8 52 22 59 49 59 245 l0 175 25 0 c16
      0 25 6 25 15 0 11 -11 15 -40 15 -28 0 -40 -4 -40 -15 0 -11 -8 -11 -42 5 -43
      19 -65 22 -103 13z m113 -50 l32 -15 0 -97 0 -97 -41 -23 c-105 -59 -180 2
      -166 134 7 64 23 93 62 111 32 14 61 10 113 -13z"/>
                        <path d="M4395 523 c-65 -18 -97 -74 -97 -172 0 -111 27 -153 112 -172 37 -8
      116 6 126 23 13 21 -13 27 -52 13 -81 -28 -140 10 -151 95 l-6 40 122 0 121 0
      0 35 c0 49 -23 100 -54 120 -27 18 -88 27 -121 18z m113 -58 c12 -14 22 -39
      22 -56 l0 -29 -100 0 c-55 0 -100 4 -100 9 0 29 27 76 54 92 41 26 93 19 124
      -16z"/>
                        <path d="M4738 520 c-15 -4 -39 -18 -54 -29 -35 -27 -44 -26 -44 4 0 16 -6 25
      -15 25 -13 0 -15 -26 -15 -170 0 -144 2 -170 15 -170 12 0 15 20 15 118 0 116
      1 119 28 145 54 51 125 65 154 29 9 -10 15 -62 18 -151 4 -112 8 -136 20 -136
      12 0 16 19 18 100 2 55 0 119 -3 143 -11 75 -69 114 -137 92z"/>
                        <path d="M4991 514 c-45 -19 -60 -41 -61 -86 0 -47 27 -69 119 -97 67 -20 81
      -33 81 -72 0 -46 -112 -70 -166 -34 -38 24 -53 4 -18 -24 52 -41 189 -25 208
      24 13 34 6 84 -14 103 -11 10 -48 27 -83 36 -70 20 -97 39 -97 71 0 54 77 79
      140 46 45 -23 70 -20 48 6 -17 20 -71 43 -100 43 -13 -1 -38 -7 -57 -16z"/>
                  </g>
            </svg>
      )
}

Logo.propTypes = {
      height: PropTypes.number,
      width: PropTypes.number
}

export function CompanyName({ fillColor, align = {}, isSmall, ...props }) {

      const svgWidth = isSmall ? '10' : '17'
      const svgHeight = isSmall ? '5' : '9'

      const view = [
            align.left ?? 0,
            align.top ?? 0,
            align.right ?? 516,
            align.bottom ?? 272
      ].join(' ')

      return (
            <svg
                  {...props}
                  version="1.0"
                  width={`${svgWidth}0.000000pt`}
                  height={`${svgHeight}0.000000pt`}
                  viewBox={view}
            >
                  <g fill={fillColor} transform="translate(0.00000,172.000000) scale(0.100000,-0.100000)"
                        stroke="none">
                        <path d="M3557 663 c-4 -3 -7 -106 -7 -228 0 -199 2 -223 18 -237 23 -21 55
        -30 76 -22 24 9 19 24 -8 24 -53 0 -56 13 -56 251 0 200 -4 232 -23 212z"/>
                        <path d="M2910 422 l0 -239 33 0 c17 0 60 -2 94 -3 76 -3 106 14 134 73 38 85
        15 227 -43 258 -38 21 -114 16 -148 -9 -16 -12 -32 -22 -35 -22 -3 0 -5 41 -5
        90 0 73 -3 90 -15 90 -13 0 -15 -33 -15 -238z m217 43 c45 -51 42 -178 -7
        -227 -24 -23 -37 -28 -79 -28 -27 0 -61 4 -75 10 -26 9 -26 11 -26 113 0 115
        3 122 70 151 44 19 88 12 117 -19z"/>
                        <path d="M196 626 c-52 -20 -101 -69 -133 -135 -52 -106 -32 -260 38 -296 43
        -23 108 -18 157 10 45 26 48 23 32 -37 -16 -56 -51 -78 -122 -78 -32 0 -74 6
        -92 14 -33 14 -33 14 -50 -21 -9 -19 -14 -38 -11 -43 8 -13 99 -30 158 -30
        102 0 168 35 201 105 10 20 35 119 56 221 21 101 43 191 50 199 6 7 21 15 33
        17 15 2 23 13 28 35 3 18 4 35 1 38 -3 3 -29 5 -59 5 -82 0 -107 -36 -139
        -197 -16 -77 -22 -91 -59 -130 -34 -35 -48 -43 -78 -43 -30 0 -39 5 -52 30
        -31 60 -6 181 50 237 28 28 40 33 82 33 43 0 50 3 65 30 11 19 13 32 7 36 -23
        14 -125 14 -163 0z"/>
                        <path d="M1030 538 c-56 -16 -91 -48 -118 -106 -33 -72 -35 -169 -5 -210 40
        -55 125 -60 187 -11 l26 20 0 -25 c0 -22 4 -26 30 -26 17 0 30 1 30 3 0 2 16
        82 35 177 19 95 35 175 35 177 0 2 -24 2 -52 1 -29 -2 -71 0 -93 4 -22 4 -56
        2 -75 -4z m113 -67 c6 -6 4 -37 -4 -83 -11 -62 -18 -78 -50 -110 -42 -44 -91
        -52 -109 -19 -26 50 -3 150 46 199 30 30 39 34 71 29 20 -4 41 -11 46 -16z"/>
                        <path d="M1405 541 c-67 -17 -127 -74 -144 -138 -29 -103 -3 -190 63 -214 20
        -7 36 -19 36 -27 0 -8 7 -20 17 -27 28 -21 5 -38 -43 -32 -37 4 -44 1 -50 -15
        -9 -28 0 -33 66 -33 43 0 64 5 79 19 25 22 28 74 6 82 -25 10 -17 21 18 28 52
        10 61 19 47 47 -7 13 -14 25 -15 27 -2 2 -21 -2 -43 -9 -57 -17 -88 -6 -102
        35 -12 38 0 111 25 150 29 44 93 59 132 31 18 -12 27 -13 48 -3 32 15 29 37
        -10 59 -37 22 -91 30 -130 20z"/>
                        <path d="M580 536 c0 -2 -11 -58 -25 -126 -37 -184 -33 -212 33 -230 41 -11
        87 -2 128 25 23 15 24 14 24 -5 0 -18 5 -21 37 -18 l37 3 33 160 c41 206 42
        195 -6 195 l-38 0 -24 -122 c-23 -117 -26 -124 -58 -145 -34 -23 -95 -31 -106
        -14 -3 5 6 67 20 138 14 70 25 131 25 136 0 4 -18 7 -40 7 -22 0 -40 -2 -40
        -4z"/>
                        <path d="M1605 528 c-15 -44 -52 -271 -48 -293 11 -57 94 -75 171 -36 42 21
        42 21 42 1 0 -16 7 -20 35 -20 19 0 35 1 35 3 0 2 16 82 35 177 19 95 35 175
        35 177 0 2 -18 3 -40 3 -33 0 -41 -4 -45 -22 -7 -30 -45 -219 -45 -224 0 -2
        -18 -13 -40 -24 -47 -24 -88 -26 -97 -4 -5 13 23 177 43 252 6 20 3 22 -35 22
        -24 0 -43 -5 -46 -12z"/>
                        <path d="M2166 516 c-40 -15 -46 -21 -71 -68 -21 -38 -20 -160 1 -204 18 -38
        40 -53 94 -65 37 -8 116 6 126 23 13 21 -13 27 -53 13 -35 -12 -49 -12 -79 -2
        -41 13 -74 62 -74 109 l0 28 120 0 120 0 0 35 c0 43 -20 94 -46 117 -28 24
        -92 31 -138 14z m90 -26 c37 -14 53 -38 54 -77 l0 -33 -101 0 c-99 0 -100 0
        -94 23 13 45 31 70 60 83 37 16 47 17 81 4z"/>
                        <path d="M2495 513 c-16 -8 -38 -22 -47 -31 -17 -14 -18 -13 -18 11 0 20 -5
        27 -20 27 -20 0 -20 -5 -18 -167 3 -142 5 -168 18 -168 12 0 15 21 18 122 l3
        121 35 31 c47 42 86 45 117 9 14 -17 17 -41 17 -155 0 -123 2 -134 18 -131 15
        3 17 18 19 122 1 114 2 119 28 147 50 51 110 56 134 10 6 -11 11 -77 11 -150
        0 -110 2 -131 15 -131 13 0 15 22 15 140 0 153 -7 182 -51 200 -37 16 -75 6
        -121 -31 l-38 -30 -11 24 c-12 26 -48 47 -77 47 -9 -1 -30 -8 -47 -17z"/>
                        <path d="M3287 515 c-47 -16 -51 -20 -43 -40 5 -14 8 -14 28 -1 80 50 178 15
        178 -63 0 -15 -12 -20 -72 -26 -85 -8 -132 -25 -147 -53 -16 -30 -13 -94 4
        -119 33 -47 126 -47 185 -1 l25 20 3 -26 c2 -14 10 -26 18 -26 21 0 20 259 -1
        300 -24 46 -104 62 -178 35z m163 -205 c0 -48 -1 -50 -47 -77 -78 -46 -153
        -25 -153 42 0 52 57 81 163 84 l37 1 0 -50z"/>
                        <path d="M3727 515 c-47 -16 -51 -20 -43 -40 5 -14 8 -14 28 -1 80 50 178 15
        178 -63 0 -15 -12 -20 -72 -26 -85 -8 -132 -25 -147 -53 -16 -30 -13 -94 4
        -119 33 -47 126 -47 185 -1 l25 20 3 -26 c2 -14 10 -26 18 -26 21 0 20 259 -1
        300 -24 46 -104 62 -178 35z m163 -205 c0 -48 -1 -50 -47 -77 -78 -46 -153
        -25 -153 42 0 52 57 81 163 84 l37 1 0 -50z"/>
                        <path d="M4065 523 c-57 -14 -95 -83 -95 -173 0 -72 19 -122 54 -145 37 -24
        107 -23 151 4 l38 22 -5 -52 c-7 -68 -31 -89 -100 -89 -27 0 -59 5 -70 11 -26
        14 -49 -4 -30 -23 18 -18 136 -23 173 -8 52 22 59 49 59 245 l0 175 25 0 c16
        0 25 6 25 15 0 11 -11 15 -40 15 -28 0 -40 -4 -40 -15 0 -11 -8 -11 -42 5 -43
        19 -65 22 -103 13z m113 -50 l32 -15 0 -97 0 -97 -41 -23 c-105 -59 -180 2
        -166 134 7 64 23 93 62 111 32 14 61 10 113 -13z"/>
                        <path d="M4395 523 c-65 -18 -97 -74 -97 -172 0 -111 27 -153 112 -172 37 -8
        116 6 126 23 13 21 -13 27 -52 13 -81 -28 -140 10 -151 95 l-6 40 122 0 121 0
        0 35 c0 49 -23 100 -54 120 -27 18 -88 27 -121 18z m113 -58 c12 -14 22 -39
        22 -56 l0 -29 -100 0 c-55 0 -100 4 -100 9 0 29 27 76 54 92 41 26 93 19 124
        -16z"/>
                        <path d="M4738 520 c-15 -4 -39 -18 -54 -29 -35 -27 -44 -26 -44 4 0 16 -6 25
        -15 25 -13 0 -15 -26 -15 -170 0 -144 2 -170 15 -170 12 0 15 20 15 118 0 116
        1 119 28 145 54 51 125 65 154 29 9 -10 15 -62 18 -151 4 -112 8 -136 20 -136
        12 0 16 19 18 100 2 55 0 119 -3 143 -11 75 -69 114 -137 92z"/>
                        <path d="M4991 514 c-45 -19 -60 -41 -61 -86 0 -47 27 -69 119 -97 67 -20 81
        -33 81 -72 0 -46 -112 -70 -166 -34 -38 24 -53 4 -18 -24 52 -41 189 -25 208
        24 13 34 6 84 -14 103 -11 10 -48 27 -83 36 -70 20 -97 39 -97 71 0 54 77 79
        140 46 45 -23 70 -20 48 6 -17 20 -71 43 -100 43 -13 -1 -38 -7 -57 -16z"/>
                  </g>
            </svg>

      )
}

export default Logo

CompanyName.propTypes = {
      fillColor: PropTypes.string,
      isSmall: PropTypes.bool,
      align: PropTypes.shape({
            left: PropTypes.number,
            right: PropTypes.number,
            top: PropTypes.number,
            bottom: PropTypes.number,
      })
}

CompanyName.defaultProps = {
      fillColor: '#FFF',
      isSmall: false,

      align: {
            left: 0,
            top: 0,
            right: 516,
            bottom: 272
      }
}

export function ReportLogo({
      fillColor,
      small,
      ...props
}) {

      const svgW = small ? '6' : '18'
      const svgH = small ? '2' : '4'

      return (
            <svg
                  style={{
                        marginTop: "10px"
                  }}
                 {...props}
                  version="1.0"
               
                  width={`${svgW}0.000000pt`}
                  height={`${svgH}0.000000pt`}
                  viewBox="0 0 700.000000 156.000000">
            
                  <g transform="translate(5.00000,96.000000) scale(0.100000,-0.100000)">
                        <path d="M3557 663 c-4 -3 -7 -106 -7 -228 0 -199 2 -223 18 -237 23 -21 55
        -30 76 -22 24 9 19 24 -8 24 -53 0 -56 13 -56 251 0 200 -4 232 -23 212z"/>
                        <path d="M2910 422 l0 -239 33 0 c17 0 60 -2 94 -3 76 -3 106 14 134 73 38 85
        15 227 -43 258 -38 21 -114 16 -148 -9 -16 -12 -32 -22 -35 -22 -3 0 -5 41 -5
        90 0 73 -3 90 -15 90 -13 0 -15 -33 -15 -238z m217 43 c45 -51 42 -178 -7
        -227 -24 -23 -37 -28 -79 -28 -27 0 -61 4 -75 10 -26 9 -26 11 -26 113 0 115
        3 122 70 151 44 19 88 12 117 -19z"/>
                        <path d="M196 626 c-52 -20 -101 -69 -133 -135 -52 -106 -32 -260 38 -296 43
        -23 108 -18 157 10 45 26 48 23 32 -37 -16 -56 -51 -78 -122 -78 -32 0 -74 6
        -92 14 -33 14 -33 14 -50 -21 -9 -19 -14 -38 -11 -43 8 -13 99 -30 158 -30
        102 0 168 35 201 105 10 20 35 119 56 221 21 101 43 191 50 199 6 7 21 15 33
        17 15 2 23 13 28 35 3 18 4 35 1 38 -3 3 -29 5 -59 5 -82 0 -107 -36 -139
        -197 -16 -77 -22 -91 -59 -130 -34 -35 -48 -43 -78 -43 -30 0 -39 5 -52 30
        -31 60 -6 181 50 237 28 28 40 33 82 33 43 0 50 3 65 30 11 19 13 32 7 36 -23
        14 -125 14 -163 0z"/>
                        <path d="M1030 538 c-56 -16 -91 -48 -118 -106 -33 -72 -35 -169 -5 -210 40
        -55 125 -60 187 -11 l26 20 0 -25 c0 -22 4 -26 30 -26 17 0 30 1 30 3 0 2 16
        82 35 177 19 95 35 175 35 177 0 2 -24 2 -52 1 -29 -2 -71 0 -93 4 -22 4 -56
        2 -75 -4z m113 -67 c6 -6 4 -37 -4 -83 -11 -62 -18 -78 -50 -110 -42 -44 -91
        -52 -109 -19 -26 50 -3 150 46 199 30 30 39 34 71 29 20 -4 41 -11 46 -16z"/>
                        <path d="M1405 541 c-67 -17 -127 -74 -144 -138 -29 -103 -3 -190 63 -214 20
        -7 36 -19 36 -27 0 -8 7 -20 17 -27 28 -21 5 -38 -43 -32 -37 4 -44 1 -50 -15
        -9 -28 0 -33 66 -33 43 0 64 5 79 19 25 22 28 74 6 82 -25 10 -17 21 18 28 52
        10 61 19 47 47 -7 13 -14 25 -15 27 -2 2 -21 -2 -43 -9 -57 -17 -88 -6 -102
        35 -12 38 0 111 25 150 29 44 93 59 132 31 18 -12 27 -13 48 -3 32 15 29 37
        -10 59 -37 22 -91 30 -130 20z"/>
                        <path d="M580 536 c0 -2 -11 -58 -25 -126 -37 -184 -33 -212 33 -230 41 -11
        87 -2 128 25 23 15 24 14 24 -5 0 -18 5 -21 37 -18 l37 3 33 160 c41 206 42
        195 -6 195 l-38 0 -24 -122 c-23 -117 -26 -124 -58 -145 -34 -23 -95 -31 -106
        -14 -3 5 6 67 20 138 14 70 25 131 25 136 0 4 -18 7 -40 7 -22 0 -40 -2 -40
        -4z"/>
                        <path d="M1605 528 c-15 -44 -52 -271 -48 -293 11 -57 94 -75 171 -36 42 21
        42 21 42 1 0 -16 7 -20 35 -20 19 0 35 1 35 3 0 2 16 82 35 177 19 95 35 175
        35 177 0 2 -18 3 -40 3 -33 0 -41 -4 -45 -22 -7 -30 -45 -219 -45 -224 0 -2
        -18 -13 -40 -24 -47 -24 -88 -26 -97 -4 -5 13 23 177 43 252 6 20 3 22 -35 22
        -24 0 -43 -5 -46 -12z"/>
                        <path d="M2166 516 c-40 -15 -46 -21 -71 -68 -21 -38 -20 -160 1 -204 18 -38
        40 -53 94 -65 37 -8 116 6 126 23 13 21 -13 27 -53 13 -35 -12 -49 -12 -79 -2
        -41 13 -74 62 -74 109 l0 28 120 0 120 0 0 35 c0 43 -20 94 -46 117 -28 24
        -92 31 -138 14z m90 -26 c37 -14 53 -38 54 -77 l0 -33 -101 0 c-99 0 -100 0
        -94 23 13 45 31 70 60 83 37 16 47 17 81 4z"/>
                        <path d="M2495 513 c-16 -8 -38 -22 -47 -31 -17 -14 -18 -13 -18 11 0 20 -5
        27 -20 27 -20 0 -20 -5 -18 -167 3 -142 5 -168 18 -168 12 0 15 21 18 122 l3
        121 35 31 c47 42 86 45 117 9 14 -17 17 -41 17 -155 0 -123 2 -134 18 -131 15
        3 17 18 19 122 1 114 2 119 28 147 50 51 110 56 134 10 6 -11 11 -77 11 -150
        0 -110 2 -131 15 -131 13 0 15 22 15 140 0 153 -7 182 -51 200 -37 16 -75 6
        -121 -31 l-38 -30 -11 24 c-12 26 -48 47 -77 47 -9 -1 -30 -8 -47 -17z"/>
                        <path d="M3287 515 c-47 -16 -51 -20 -43 -40 5 -14 8 -14 28 -1 80 50 178 15
        178 -63 0 -15 -12 -20 -72 -26 -85 -8 -132 -25 -147 -53 -16 -30 -13 -94 4
        -119 33 -47 126 -47 185 -1 l25 20 3 -26 c2 -14 10 -26 18 -26 21 0 20 259 -1
        300 -24 46 -104 62 -178 35z m163 -205 c0 -48 -1 -50 -47 -77 -78 -46 -153
        -25 -153 42 0 52 57 81 163 84 l37 1 0 -50z"/>
                        <path d="M3727 515 c-47 -16 -51 -20 -43 -40 5 -14 8 -14 28 -1 80 50 178 15
        178 -63 0 -15 -12 -20 -72 -26 -85 -8 -132 -25 -147 -53 -16 -30 -13 -94 4
        -119 33 -47 126 -47 185 -1 l25 20 3 -26 c2 -14 10 -26 18 -26 21 0 20 259 -1
        300 -24 46 -104 62 -178 35z m163 -205 c0 -48 -1 -50 -47 -77 -78 -46 -153
        -25 -153 42 0 52 57 81 163 84 l37 1 0 -50z"/>
                        <path d="M4065 523 c-57 -14 -95 -83 -95 -173 0 -72 19 -122 54 -145 37 -24
        107 -23 151 4 l38 22 -5 -52 c-7 -68 -31 -89 -100 -89 -27 0 -59 5 -70 11 -26
        14 -49 -4 -30 -23 18 -18 136 -23 173 -8 52 22 59 49 59 245 l0 175 25 0 c16
        0 25 6 25 15 0 11 -11 15 -40 15 -28 0 -40 -4 -40 -15 0 -11 -8 -11 -42 5 -43
        19 -65 22 -103 13z m113 -50 l32 -15 0 -97 0 -97 -41 -23 c-105 -59 -180 2
        -166 134 7 64 23 93 62 111 32 14 61 10 113 -13z"/>
                        <path d="M4395 523 c-65 -18 -97 -74 -97 -172 0 -111 27 -153 112 -172 37 -8
        116 6 126 23 13 21 -13 27 -52 13 -81 -28 -140 10 -151 95 l-6 40 122 0 121 0
        0 35 c0 49 -23 100 -54 120 -27 18 -88 27 -121 18z m113 -58 c12 -14 22 -39
        22 -56 l0 -29 -100 0 c-55 0 -100 4 -100 9 0 29 27 76 54 92 41 26 93 19 124
        -16z"/>
                        <path d="M4738 520 c-15 -4 -39 -18 -54 -29 -35 -27 -44 -26 -44 4 0 16 -6 25
        -15 25 -13 0 -15 -26 -15 -170 0 -144 2 -170 15 -170 12 0 15 20 15 118 0 116
        1 119 28 145 54 51 125 65 154 29 9 -10 15 -62 18 -151 4 -112 8 -136 20 -136
        12 0 16 19 18 100 2 55 0 119 -3 143 -11 75 -69 114 -137 92z"/>
                        <path d="M4991 514 c-45 -19 -60 -41 -61 -86 0 -47 27 -69 119 -97 67 -20 81
        -33 81 -72 0 -46 -112 -70 -166 -34 -38 24 -53 4 -18 -24 52 -41 189 -25 208
        24 13 34 6 84 -14 103 -11 10 -48 27 -83 36 -70 20 -97 39 -97 71 0 54 77 79
        140 46 45 -23 70 -20 48 6 -17 20 -71 43 -100 43 -13 -1 -38 -7 -57 -16z"/>
                  </g>
            </svg>
      )
}

ReportLogo.propTypes = {
      fillColor: PropTypes.string,
      small: PropTypes.bool
}

ReportLogo.defaultProps = {
      fillColor: '#FFF',
      small: false
}

