// --> LIBRARIES
import React, { useState } from "react";
import "fontsource-roboto";
// --> APPLICATION SERVICES
import { isAuthenticated } from "./services/auth";
import { navbarPaths } from "./services/api_base";
import APIs from "./services/api_base";
import ApplicationContext from "./helpers/context";
import { CONTEXT_KEYS } from "./consts";
// --> COMPONENTS
import CssBaseline from "@material-ui/core/CssBaseline";
import { AjaxLoader, AppNav, Alert } from "./components";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./routes";
import Confirmation from "./components/Confirmation";

const App = () => {
  const [currentUser, setCurrentUser] = useState({});
  const authenticated = isAuthenticated();
  const [apiValues, setApiValues] = useState({});

  const handleApiValues = (response, key) => {
    setApiValues((previousLoader) => ({
      ...previousLoader,
      [key]: response.data,
    }));
  };

  const _context = {
    user: currentUser,
    ...apiValues,
  };

  return (
    <>
      <ApplicationContext.Provider value={{ ..._context }}>
        <Router>
          <CssBaseline />
          {authenticated && (
            <>
              <AppNav navbarConfig={navbarPaths} />
              <AjaxLoader
                url={[APIs.miscellaneous, "representative"].join("/")}
                visible={false}
                onFetch={(response) => setCurrentUser(response.data)}
              />
              <AjaxLoader
                url={[APIs.miscellaneous, "customers"].join("/")}
                onFetch={(res) => handleApiValues(res, CONTEXT_KEYS.customers)}
                visible={false}
              />
              <AjaxLoader
                url={[APIs.miscellaneous, "customer_delivery_to"].join("/")}
                onFetch={(res) =>
                  handleApiValues(res, CONTEXT_KEYS.customer_delivery_to)
                }
                visible={false}
              />
              <AjaxLoader
                url={[APIs.miscellaneous, "itineraries"].join("/")}
                onFetch={(res) =>
                  handleApiValues(res, CONTEXT_KEYS.itineraries)
                }
                visible={false}
              />
              <Alert />
              <Confirmation />
            </>
          )}
          <Routes />
        </Router>
      </ApplicationContext.Provider>
    </>
  );
};

export default App;
