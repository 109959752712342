import React, { useState } from 'react'
import theme from '../../theme'
import clsx from 'clsx'
import { useHistory } from 'react-router-dom'
import { signout } from '../../services/auth'
import { formatter } from '../../helpers'
import { useRepresentative } from '../../hooks'
import { List as ListIcon, Home, Menu as MenuIcon, ExpandLess, ExpandMore } from '@material-ui/icons'
import { List, ListItem, ListItemText, ListItemIcon, Divider, Collapse, useMediaQuery, Typography, Avatar, Box, Tooltip } from '@material-ui/core';
import { StyledAppNavbar, MenuArea, Drawer, AvatarArea, useStyles } from './styles'
import { Button } from '../Button/index'
import { CompanyName } from '../company_logo'

export function AppNav({ navbarConfig, ...props }) {

  const user = useRepresentative()

  const smallDeviseMedia = useMediaQuery(theme.breakpoints.down('sm'))
  const history = useHistory()

  const currentPathName = history.location && history.location.pathname

  const [showMenu, setShowMenu] = useState(false)
  const [subMenu, setSubMenu] = useState({})

  const handleShowMenu = () => setShowMenu(state => !state)

  const classes = useStyles()

  const navigate = (item, param = '') => {

    if (!item.subMenu || param) {
      handleShowMenu()
      if (currentPathName !== item.path) history.push([item.path, param].join(param ? `?${item.id}=` : ''))
      return
    }

    setSubMenu({ ...subMenu, [item.id]: !subMenu[item.id] })
  }

  const currentUser = user && user.id ? {
    id: formatter(user.id).toCNPJ(),
    company: user.emp_razao_social,
    avatar: user.emp_razao_social.slice(0, 2).toUpperCase()
  } : {}


  return (
    <StyledAppNavbar
      {...props}
      position='static'>

      <Drawer
        anchor={smallDeviseMedia ? 'top' : 'left'}
        open={showMenu}
        onClose={handleShowMenu}
        onOpen={handleShowMenu}
      >
        <List className='list' disablePadding>
          <Box display="flex" justifyContent="center">
            <CompanyName
              isSmall={true}
              align={{
                top: 30,
                bottom: 217
              }}
            />
          </Box>
          <Divider />
          {navbarConfig && navbarConfig.map((item, i) => {
            return (
              <React.Fragment key={i}>
                <ListItem className={clsx('item', currentPathName === item.path ? 'active' : '')} onClick={() => navigate(item)}>
                  <ListItemIcon>
                    {item.icon === 'home' ? <Home /> : <ListIcon />}
                  </ListItemIcon>
                  <ListItemText primary={item.label} />
                  {
                    item.subMenu ? subMenu[item.id] ? <ExpandLess /> : <ExpandMore /> : false
                  }
                </ListItem>
                {item.subMenu &&
                  <Collapse in={subMenu[item.id]} timeout='auto' unmountOnExit>
                    <List component='div' disablePadding>
                      {
                        item.subMenu.map((menu, i) => (
                          <ListItem className='item sub-item' key={i} onClick={() => navigate(item, menu.param)}>
                            <ListItemText primary={menu.label} />
                          </ListItem>
                        ))
                      }
                    </List>
                  </Collapse>
                }
                <Divider />
              </React.Fragment>
            )
          })}
        </List>
      </Drawer>
      <MenuArea>
        <Box>
          <Button
            type='icon'
            label='Menu'
            icon={<MenuIcon />}
            size='medium'
            color='inherit'
            onClick={handleShowMenu}
          />
          {!smallDeviseMedia && <CompanyName className={classes.logo} />}
        </Box>
        <Box className={classes.toolbar}>
          <AvatarArea>
            <Tooltip
              arrow={true}
              title={[currentUser.company, currentUser.id].join(' - ')}>
              <Avatar className={classes.avatar} >
                {currentUser.avatar}
              </Avatar>
            </Tooltip>
            {
              !smallDeviseMedia &&
              <Box paddingLeft={1}>
                <Typography component='div' variant='body2'>{currentUser.company}</Typography>
                <Typography component='div' variant='body2' align='center'>{currentUser.id}</Typography>
              </Box>
            }
          </AvatarArea>
          <Button
            label='SAIR'
            onClick={signout}
          />
        </Box>
      </MenuArea>
    </StyledAppNavbar>
  )
}
export default AppNav


