import { makeStyles } from '@material-ui/core'
import { lighten } from 'polished'

export const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.spacing(1.5),
    }
  },
  title: {
    background: theme.palette.secondary.main,
    color: '#FFF',
  },
  subtitle: {
    background: theme.palette.secondary.dark,
    color: theme.palette.secondary.light,
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    fontSize: '13px',

    '& .small-subtitle-icon': {
      fontSize: '17px',
    }
  },
  contentDefault: {
    margin: theme.spacing(0.5),
    padding: theme.spacing(2),
  },
  text: {
    background: theme.palette.background.default,
    fontSize: theme.spacing(2),
  },
  main: {
    border: props => props.bordered
      ? `2px solid ${theme.palette.secondary.main}`
      : 'none'
  },
  actions: {
    justifyContent: props => props['justify-actions'],
    borderTop: props => props.bordered
      ? `2px solid ${lighten(0.45, theme.palette.secondary.main)}`
      : 'none'
  },
  logo: {
    paddingRight: theme.spacing(1),
    margin: '5px',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },

    position: 'absolute',
    right: '0',
  },
}))