//eslint-disable
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import theme from './theme'
import 'fontsource-roboto'
import { ThemeProvider } from '@material-ui/core'

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>,
    document.body.appendChild(document.createElement('div')),
  )
})
