import React from 'react'
import { useCurrentForm } from '..'

import {
  TextField as TextFieldComponent,
  SelectField as SelectFieldComponent,
  AutocompleteField as AutocompleteFieldComponent,
  DateField as DateFieldComponent,
  NumberField as NumberFieldComponent,
  CheckboxField as CheckboxFieldComponent,
} from '../../../components'

import { isNil } from 'ramda'

const withCurrentForm = (Component) => {
  const WithCurrentForm = ({ name, splitter, ...props }) => {
    const { getFieldValue, handleChange } = useCurrentForm()
    const fieldValue = getFieldValue(name, splitter)
    const value = isNil(fieldValue) ? '' : fieldValue

    return (
      <Component name={name} value={value} onChange={handleChange(splitter)} {...props} />
    )
  }
  WithCurrentForm.WrappedComponent = Component

  return WithCurrentForm
}

export const TextField = withCurrentForm(TextFieldComponent)
export const SelectField = withCurrentForm(SelectFieldComponent)
export const AutocompleteField = withCurrentForm(AutocompleteFieldComponent)
export const DateField = withCurrentForm(DateFieldComponent)
export const NumberField = withCurrentForm(NumberFieldComponent)
export const CheckboxField = withCurrentForm(CheckboxFieldComponent)
