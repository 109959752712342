import React from 'react'
import PropTypes from 'prop-types'
import { StyledIconButton, StyledButton } from './styles'
import { Tooltip } from '@material-ui/core'

export function Button({
  label,
  variant,
  type,
  size,
  icon,
  disabled,
  ...props
}) {

  return (
    type === 'icon' ?
      <StyledIconButton
        size={size}
        disabled={disabled}
        {...props}
      >
        <Tooltip title={label}>
          {icon && icon}
        </Tooltip>
      </StyledIconButton>
      :
      <StyledButton
        variant='contained'
        disabled={disabled}
        {...props}
      >
        {label && label}
      </StyledButton>
  )
}

Button.propTypes = {
  icon: PropTypes.object,
  label: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
}

Button.defaultProps = {
  type: 'button',
  size: 'small',
}

export default Button