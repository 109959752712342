import styled from 'styled-components'
import { lighten } from 'polished'
import theme from '../../theme'
import {
  Button as MuiButton,
  IconButton as MuiIconButton
} from '@material-ui/core'

const buttonBg = (scale = 0) => props => {
  return lighten(scale, theme.palette[props.background || 'secondary']?.main) ?? 'inherit'
}

export const StyledButton = styled(MuiButton)`
  color: #FFF;
  background-color: ${buttonBg()};

  transition: ${theme.transitions.default};
  border-radius: 3px;
  &:hover {
    background-color: ${buttonBg(0.1)};
    transition: ${theme.transitions.default};
    box-shadow: ${theme.shadows[2]};
  }
  &:focus {
    outline:none;
  }
`
export const StyledIconButton = styled(MuiIconButton)`
  &:hover {
    color: ${props => props.size === 'small' ? theme.palette[props.background || 'secondary'].main : '#FFF'};
    background-color: ${props => props.size !== 'small' ? theme.palette[props.background || 'secondary'].main : 'inherit'};
  }
  &:focus {
    outline:none;
  }
  * {
    font-size: ${props => props.size === 'small' && '1.2rem'};
  }
  `