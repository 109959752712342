import React from 'react'
import { FormControlLabel, Checkbox } from '@material-ui/core'

export function CheckboxField({ name, label, value, onChange, ...props }) {
  
  const handleChange = event => {
    onChange({ target: { name, value: event.target.checked } })
  }

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={Boolean(value)}
          onChange={handleChange}
          color="default"
          {...props}
        />
      }
      label={label}
    />
  )
}

export default CheckboxField
