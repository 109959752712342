import React from 'react'
import Proptypes from 'prop-types'
import clsx from 'clsx'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
} from '@material-ui/core'

import { useStyles } from './styles'

import { Button, LoaderButton } from '..'
import Logo from '../company_logo'

export function Modal(props) {

  const {
    open,
    onClose,
    onAction,
    inAction,
    actionProps,
    closeProps,
    size,
    type,
    current,
    actionParams,
    currentProp,
    title,
    subtitle,
    content,
    contentProps,
    contentClass,
    parameters,
    bordered,
    showCompanyMark,
    otherActions,
    ...other
  } = props

  const DEFAULT_MESSAGE_ACTION = 'Atenção: Essa ação não poderá ser revertida!'

  const classes = useStyles({ ...props })

  const handleAction = () => onAction(current, { ...actionParams })

  const inLoading = inAction ?? false

  const dialogTitle = title ?? `Confirma a exclusão do ${type?.toLowerCase()} ( ${current[currentProp] ?? current.id} ) ?`

  const contentClasses = clsx(
    contentClass ?? classes.contentDefault, {
    [classes.text]: !content,
  })

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={size}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.root}
      bordered={`${bordered}`}
      {...other}
    >
      <DialogTitle
        id="alert-dialog-title"
        className={classes.title}
      >
        {dialogTitle}
      </DialogTitle>
      {subtitle && <Box className={classes.subtitle}>{subtitle}</Box>}
      <Box className={classes.main}>
        <DialogContent
          className={contentClasses}
          {...contentProps}
        >
          {content ? content : DEFAULT_MESSAGE_ACTION}
        </DialogContent>
        <DialogActions className={classes.actions}>
          {otherActions}
          {
            (actionProps.show ?? true) &&
            <LoaderButton
              styles={{marginTop: '-3px'}}
              inLoading={inLoading}
              onClick={handleAction}
              label={actionProps.label}
              background={actionProps.background}
            />
          }
          {
            (closeProps.show ?? true) &&
            <Button
              label={closeProps.label}
              background={closeProps.background}
              onClick={onClose}
            />
          }
        </DialogActions>
      </Box>
      {showCompanyMark && <Box className={classes.logo}><Logo /></Box>}
    </Dialog>
  )
}

Modal.propTypes = {
  current: Proptypes.object.isRequired,
  actionParams: Proptypes.object,
  currentProp: Proptypes.string,
  parameters: Proptypes.object,
  open: Proptypes.bool.isRequired,
  onClose: Proptypes.func.isRequired,

  actionProps: Proptypes.shape({
    label: Proptypes.string,
    background: Proptypes.string,
    show: Proptypes.bool
  }),

  closeProps: Proptypes.shape({
    label: Proptypes.string,
    background: Proptypes.string,
    show: Proptypes.bool,
  }),

  onAction: Proptypes.func.isRequired,
  inAction: Proptypes.bool,

  type: Proptypes.string,
  size: Proptypes.oneOf(['lg', 'md', 'sm', 'xs', 'xl']),

  title: Proptypes.string,
  subtitle: Proptypes.oneOfType([
    Proptypes.string,
    Proptypes.element,
  ]),

  'justify-actions': Proptypes.oneOf(['center', 'flex-end', 'flex-start']),

  otherActions: Proptypes.node,
  content: Proptypes.node,
  contentClass: Proptypes.string,
  bordered: Proptypes.bool,
  showCompanyMark: Proptypes.bool,
}

Modal.defaultProps = {
  current: {},
  actionParams: {},
  parameters: {},
  open: false,
  type: 'REGISTRO',
  size: 'md',

  actionProps: {
    label: 'Executar',
    background: null,
    show: true
  },

  closeProps: {
    label: 'Cancelar',
    background: 'error',
  },

  'justify-actions': 'flex-end',
  bordered: false,
  showCompanyMark: false,
}

export default Modal

