import React from 'react'

import { Avatar, Divider, Box, ListItemAvatar, ListItemText, ListItem, List } from '@material-ui/core'
import { Build, PlayForWork, LocalShipping } from '@material-ui/icons'


export default function PossibleDates({ availableDates }) {
  return (
    <>
      <Box fontSize={15}> <strong>Datas possíveis para esse Pedido</strong></Box>
      <Box>
        <List>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <Build />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Produção" secondary={availableDates?.dt_producao} />
          </ListItem>
          <Divider variant="inset" component="li" />
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <PlayForWork />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Embarque" secondary={availableDates?.dt_embarque} />
          </ListItem>
          <Divider variant="inset" component="li" />
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <LocalShipping />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Recebimento" secondary={availableDates?.dt_recebimento} />
          </ListItem>
        </List>
      </Box>
    </>
  )
}
