import React from 'react'
import { TablePagination as MuiTablePagination, makeStyles, useMediaQuery } from '@material-ui/core'

const useStyles = makeStyles(_theme => ({
  pagination: {
    overflow: 'hidden',
  }
}))

export function TablePagination({
  count,
  rowsPerPage,
  page,
  onChangePage,
  onChangeRowsPerPage,
  rowsPerPageOptions = [5, 10, 20, 30, 50, 100, 200],
}) {
  const handleRowsPerPageChange = (event) =>
    onChangeRowsPerPage(event.target.value)

  const handleChangePage = (event, newPage) => {
    onChangePage(newPage)
  }
  
  const classes = useStyles()
  const smallDeviseMedia = useMediaQuery(`(max-width:600px)`)

  return (
    <MuiTablePagination
      className={classes.pagination}
      rowsPerPageOptions={rowsPerPageOptions}
      component="div"
      count={count}
      rowsPerPage={rowsPerPage}
      page={page}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleRowsPerPageChange}
      labelRowsPerPage={!smallDeviseMedia ? "Registros por página" : ''}
    />
  )
}

export default TablePagination
