import React, { createContext, useContext, useState } from 'react'
import { lensPath, set, view, split } from 'ramda'

export const FormContext = createContext()

export const useCurrentForm = () => useContext(FormContext)

export function FormProvider({ form, children }) {
  return (
    <FormContext.Provider value={form}>
      {children}
    </FormContext.Provider>
  )
}

export function Form({ form, children, ...props }) {
  return (
    <FormProvider form={form}>
      <form {...props}>
        {children}
      </form>
    </FormProvider>
  )
}

export * from './components'

export const useForm = ({ initialValues = {} } = {}) => {
  const [values, setValues] = useState(initialValues)
  const [errors, setErrors] = useState({})

  const handleChange = splitter => event => {
    const { target: { name, value } } = event

    setFieldValue(name, value, splitter)
  }

  const getFieldValue = (fieldPath, splitter = '.') => {
    const fieldLens = lensPath(split(splitter, fieldPath))

    return view(fieldLens, values)
  }

  const setFieldValue = (fieldPath, value, splitter = '.') => {
    const fieldLens = lensPath(split(splitter, fieldPath))

    setValues(set(fieldLens, value))
  }

  return {
    values,
    setValues,
    errors,
    setErrors,
    getFieldValue,
    setFieldValue,
    handleChange
  }
}
