import { withStyles, TableRow as MuiTableRow } from '@material-ui/core'

export const TableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(MuiTableRow)

export default TableRow
