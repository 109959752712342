import React, { useState } from "react";
import PropTypes from "prop-types";
import API from "../../../services/api_base";
import {
  Form,
  NumberField,
  CheckboxField,
  AutocompleteField,
  DateField,
  TextField,
} from "../../../lib/form";
import { FormControl, Grid, Typography } from "@material-ui/core";
import ErrorMessage from "../../../components/error_message";
import { AjaxLoader, InputLoader as Loader, Modal } from "../../../components";

export function OrderItemsForm(props) {
  const { show, onClose, onSave, form, isConferred, inSaving } = props;
  const [products, setProducts] = useState([]);
  const [cardboards, setCardboards] = useState([]);
  const [pallet, setPallets] = useState([]);
  const [billingtypes, setBillingTypes] = useState([]);

  const PRODUCT_WAVE_OPTIONS = [
    { value: "B" },
    { value: "C" },
    { value: "BC" },
    { value: "E" },
  ];

  const productOptions =
    products &&
    products.map((product) => ({
      value: product.id,
      label: `${product.id} - ${product.descr}`,
    }));

  const cardboardOptions = cardboards.map((cardboard) => ({
    value: cardboard.id,
    label: cardboard.id,
  }));

  const palletOptions = pallet.map((pallet) => ({
    value: pallet.id_pallet,
    label: `${pallet.largura} x ${pallet.comprimento} - ${pallet.tipo}`,
  }));

  const billingtypeOptions = billingtypes.map((item) => ({
    value: item.billing_type.id,
    label: item.billing_type.descr,
  }));

  const selectedProduct =
    products &&
    products.find((product) => product.id === form.values.product.id);

  const selectedProductKind = () => {
    return selectedProduct && selectedProduct.kind;
  };

  const selectedProductRevisionDate = () => {
    form.values.prd_data_revisao_produto = selectedProduct
      ? selectedProduct.prd_data_revisao
      : null;

    return selectedProduct && selectedProduct.prd_data_revisao;
  };

  const prodDescr = form.values.product ? form.values.product.descr : "";

  const title = form.values.id
    ? [form.values.product.id, prodDescr ? prodDescr : null]
        .filter((e) => e !== null)
        .join(" - ")
    : "Incluir item no Pedido";

  return (
    <Modal
      size="sm"
      open={show}
      title={title}
      onAction={onSave}
      inAction={inSaving}
      current={{
        form,
        selectedProduct,
      }}
      onClose={onClose}
      actionProps={{
        label: "Salvar",
        show: !isConferred,
        background: "info",
      }}
      fullWidth
      content={
        <>
          {form.values.customer_id && (
            <AjaxLoader
              url={[
                API.miscellaneous,
                "customer_products",
                form.values.customer_id,
                form.values.billing_company_id,
              ].join("/")}
              onFetch={(response) => setProducts(response.data)}
              visible={false}
            />
          )}
          <AjaxLoader
            url={[API.miscellaneous, "cardboards"].join("/")}
            onFetch={(response) => setCardboards(response.data)}
            visible={false}
          />
          <AjaxLoader
            url={[API.miscellaneous, "pallets"].join("/")}
            onFetch={(response) => setPallets(response.data)}
            visible={false}
          />
          <AjaxLoader
            url={[API.miscellaneous, "customer_billing_types"].join("/")}
            params={{ customer_id: form.values.customer_id }}
            onFetch={(response) => setBillingTypes(response.data)}
            visible={false}
          />
          <Form form={form}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                {products && products.length > 0 ? (
                  <>
                    <AutocompleteField
                      name="product.id"
                      options={productOptions}
                      size="sm"
                      label="Produto"
                    />
                    <ErrorMessage form={form} attr="product" />
                  </>
                ) : products ? (
                  <Loader />
                ) : (
                  <Typography color="primary">
                    Nenhum registro encontrado
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                {selectedProductRevisionDate() && (
                  <FormControl fullWidth>
                    <DateField
                      name="prd_data_revisao"
                      label="Data de Revisão"
                    />
                    <ErrorMessage form={form} attr="prd_data_revisao" />
                  </FormControl>
                )}
              </Grid>
              <Grid item xs={12}>
                {billingtypes && billingtypes.length > 0 ? (
                  <>
                    <AutocompleteField
                      name="billing_type.id"
                      options={billingtypeOptions}
                      size="sm"
                      label="Tipo de Faturamento"
                    />
                    <ErrorMessage form={form} attr="billing_type" />
                  </>
                ) : billingtypes ? (
                  <Loader />
                ) : (
                  <Typography color="primary">
                    Nenhum registro encontrado
                  </Typography>
                )}
              </Grid>

              <Grid item xs={6}>
                <FormControl fullWidth>
                  <NumberField
                    name="itped_qtd"
                    decimalScale={2}
                    label="Quantidade"
                  />
                  <ErrorMessage form={form} attr="itped_qtd" />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <NumberField
                    name="itped_preco"
                    decimalScale={4}
                    label="Valor"
                  />
                  <ErrorMessage form={form} attr="itped_preco" />
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <FormControl fullWidth>
                  <NumberField
                    name="itped_desconto"
                    decimalScale={2}
                    label="Desconto"
                  />
                  <ErrorMessage form={form} attr="itped_desconto" />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <TextField name="itped_seu_item" label="Seu Item" />
                  <ErrorMessage form={form} attr="itped_seu_item" />
                </FormControl>
              </Grid>

              {selectedProductKind() === "sheet" && (
                <>
                  <Grid item xs={6}>
                    {cardboardOptions.length > 0 ? (
                      <AutocompleteField
                        name="cod_papelao"
                        options={cardboardOptions}
                        size="sm"
                        label="Papelão"
                      />
                    ) : (
                      <Loader />
                    )}
                    <ErrorMessage form={form} attr="cod_papelao" />
                  </Grid>
                  <Grid item xs={6}>
                    <AutocompleteField
                      name="itped_onda"
                      options={PRODUCT_WAVE_OPTIONS}
                      size="sm"
                      label="Onda"
                    />
                    <ErrorMessage form={form} attr="itped_onda" />
                  </Grid>

                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <NumberField
                        name="itped_largura"
                        decimalScale={0}
                        label="Largura"
                      />
                      <ErrorMessage form={form} attr="itped_largura" />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <NumberField
                        name="itped_comprimento"
                        decimalScale={0}
                        label="Comprimento"
                      />
                      <ErrorMessage form={form} attr="itped_comprimento" />
                    </FormControl>
                  </Grid>

                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <CheckboxField
                        label="Resina interna"
                        name="itped_resina_interna"
                      />
                      <ErrorMessage form={form} attr="itped_resina_interna" />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <CheckboxField
                        label="Resina externa"
                        name="itped_resina_externa"
                      />
                      <ErrorMessage form={form} attr="itped_resina_externa" />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      {palletOptions.length > 0 ? (
                        <AutocompleteField
                          name="itped_cod_pallet"
                          label="Pallet"
                          options={palletOptions}
                          size="sm"
                        />
                      ) : (
                        <Loader />
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <NumberField
                        name="itped_pecas_pallet"
                        label="Peças/Pallet"
                      />
                      <ErrorMessage form={form} attr="itped_pecas_pallet" />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <NumberField
                        name="itped_vinco1"
                        decimalScale={0}
                        label="Vinco 1"
                      />
                      <ErrorMessage form={form} attr="itped_vinco1" />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <NumberField
                        name="itped_vinco2"
                        decimalScale={0}
                        label="Vinco 2"
                      />
                      <ErrorMessage form={form} attr="itped_vinco2" />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <NumberField
                        name="itped_vinco3"
                        decimalScale={0}
                        label="Vinco 3"
                      />
                      <ErrorMessage form={form} attr="itped_vinco3" />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <NumberField
                        name="itped_vinco4"
                        decimalScale={0}
                        label="Vinco 4"
                      />
                      <ErrorMessage form={form} attr="itped_vinco4" />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <NumberField
                        name="itped_vinco5"
                        decimalScale={0}
                        label="Vinco 5"
                      />
                      <ErrorMessage form={form} attr="itped_vinco5" />
                    </FormControl>
                  </Grid>
                </>
              )}
            </Grid>
          </Form>
        </>
      }
    />
  );
}

export default OrderItemsForm;

OrderItemsForm.propTypes = {
  isConferred: PropTypes.bool.isRequired,
  form: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

OrderItemsForm.defaultProps = {
  isConferred: false,
};
