const navbarSize = 64
const headerSize = 80
const paginatorSize = 68
const footerSize = 36

const WINDOW_DIF = Math.ceil(navbarSize + headerSize + paginatorSize + footerSize)

const DIF_LARGE_WINDOW = Math.ceil(WINDOW_DIF + 48 + 12)
// NAVBAR + PADDING + HEADER + PAGINATOR + FOOTER

const DIF_SMALL_WINDOW = Math.ceil(WINDOW_DIF + 4)
// NAVBAR + HEADER + PAGINATOR + FOOTER

const DEFAULT_CELL_ALIGN = 'left'

const actionTypes = ['edit', 'remove', 'print']
const flagTypes = ['chip', 'flag', 'avatar']

export {
  DIF_LARGE_WINDOW,
  DIF_SMALL_WINDOW,
  DEFAULT_CELL_ALIGN,
  actionTypes,
  flagTypes
}