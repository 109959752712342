import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import { Button } from '.'
import { Box, CircularProgress, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  customButton: {
    position: 'relative',
  },
  loader: {
    display: 'none',
    justifyContent: 'center',
    padding: theme.spacing(1),
    width: '100%',
    marginTop: '20px',

    position: 'absolute',
    top: 0,
  },
  visible: {
    display: 'flex',
  }
}))

export function LoaderButton({ inLoading, loadingLabel,loaderSize, styles, ...props }) {

  const classes = useStyles()

  const loaderClasses = clsx(
    classes.loader,
    { [classes.visible]: inLoading }
  )

  return (
    <Box className={classes.customButton}>
      <Button
        {...props}
        disabled={inLoading || props.disabled}
      />
      <Box className={loaderClasses} style={styles}>
        <CircularProgress size={loaderSize} color='secondary' />
      </Box>
    </Box>
  )
}

export default LoaderButton

LoaderButton.propTypes = {
  inLoading: PropTypes.bool.isRequired,
  loadingLabel: PropTypes.string,
  loaderSize: PropTypes.number,
  styles: PropTypes.object
}
LoaderButton.defaultProps = {
  loaderSize: 25
}