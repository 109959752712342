export const API_BASE = process.env.REACT_APP_API_RUBY

export const API = {
  miscellaneous: [API_BASE, 'miscellaneous'].join('/'),
  orders: [API_BASE, 'orders'].join('/')
}

export default API

export const navbarPaths = [
  {
    id: 'home',
    path: `/home`,
    label: 'Home',
    icon: 'home'
  },
  {
    id: 'orders',
    path: `/orders`,
    label: 'Meus Pedidos',
  },
]

export const anchors = navbarPaths.map(path => ({
  [path.id]: false
}))