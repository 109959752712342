import React from 'react'
import { path, isNil, take, all, complement, anyPass } from 'ramda'
import { alertService } from '../components/services'
import { formatter } from './formatter'
export const isEmptyObject = obj => Object.keys(obj).length === 0

export const handleFeedBack = message =>
  alertService.success(message)

export const handleAjaxError = err => {
  let message = `Ocorreu um erro de comunicação com o servidor: ${err}`
  if (err?.response?.status === 401) {
    message = `Usuário não autorizado`
  }
  alertService.error(message)
}

export const handleError = err => {
  alertService.error(err)
}

export const booleanIcon = value => {
  const icon = [true, 'T', 't', 'true', 'True', 'TRUE', 'S', 's', 'SIM', 'Sim', 'sim'].indexOf(value) !== -1 ? 'fa-check-square' : 'fa-square'

  return (
    <center>
      <i className={`far ${icon}`} />
    </center>
  )
}

export const isTrue = value => [true, 'T', 't', 'true', 'True', 'TRUE', 'S', 's', 'SIM', 'Sim', 'sim', '1'].includes(value)

export const sortByField = (records, field, sortOrderAsc) => {
  const fieldPath = field.split('.')

  return records.sort((a, b) => {
    if (!sortOrderAsc) [a, b] = [b, a]

    const valueA = path(fieldPath, a)
    const valueB = path(fieldPath, b)

    if (all(isNil, [valueA, valueB]))
      return 0
    else if (isNil(valueA))
      return -1
    else if (isNil(valueB))
      return 1

    return (
      typeof valueA === 'string' || typeof valueB === 'string' ?
        valueA.toString().localeCompare(valueB.toString()) :
        valueA - valueB
    )
  })
}

export const compare = (value, query) => {
  let v = value
  let q = query ? query : value

  const treatedValue = typeof v === 'string' ? v.toLowerCase() : v.toString()
  const treatedQuery = typeof q === 'string' ? q.toLowerCase() : q.toString()

  const compareRgxp = new RegExp(treatedQuery)
  const valueCompared = compareRgxp.test(treatedValue)
  return valueCompared
}

export const removeAccents = text => text.normalize('NFD').replace(/[\u0300-\u036f]/g, '')

export const isEmpty = value => value === undefined || value === null || value === ''

export const isNotEmpty = complement(isEmpty)

export const objectMap = (obj, fn) =>
  Object.fromEntries(
    Object.entries(obj).map(
      ([k, v], i) => [k, fn(v, k, i)]
    )
  )

export const displayJson = json =>
  <pre>
    {JSON.stringify(json, null, 4).replace(/"/g, '\'')}
  </pre>

export const formatDecimal = (number, decimals = 2, thousands = '.', decimal = ',') => {
  const [a, b] = number.toFixed(decimals).split('.')

  if (isNil(b)) return a

  return [
    a.replace(/\B(?=(\d{3})+(?!\d))/g, thousands),
    b,
  ].join(decimal)
}

export const formatDate = date => {
  const [year, month, day] = take(10, date).split('-')
  return `${day}/${month}/${year}`
}

export const checkIfEnterKeyPressed = event => {
  const ENTER_KEY = 13
  const keyPressed = event.which || event.charCode

  if (keyPressed === ENTER_KEY) return true

  return false
}

export const displayDisabledForm = (record, form) => event => {
  event.preventDefault()

  form.displayDisabled({ record })
}

export const handleUnprocessableEntity = form => error => {
  if (error.response.status === 422) {
    alertService.error('Por favor corrija todos os erros reportados pelo servidor.')

    form.setErrors(error.response.data.errors || {})
  } else {
    throw error
  }
}

export const CannotInsertMore = form => error => {
  if (error.response.status === 403) {
    alertService.error('Você não pode inserir mais itens nesse pedido.')

    form.setErrors(error.response.data.errors || {})
  } else {
    throw error
  }
}

export const clearSearchForm = (setSearchTerms, defaultSearchFormValues = {}) => {
  setSearchTerms(defaultSearchFormValues)

  alertService.info('Filtros limpos com sucesso.')
}

export const isEmptyOrNil = anyPass([isNil, isEmpty])

export const checksIfDateIsLessThanTheCurrent = (dateInFormatIso, offset) => {
  const formattedDate = formatter(dateInFormatIso).toDayOnly()
  const splitedDate = formattedDate?.split("/") || []

  const newDate = new Date(splitedDate[2], splitedDate[1] - 1, splitedDate[0]);
  const today = new Date(new Date().toDateString());

  const minDate = addDays(new Date(today), offset)

  return newDate < minDate
}

export const checksIfDateIsGreaterThanTheCurrent = (dateInFormatIso, offset) => !checksIfDateIsLessThanTheCurrent(dateInFormatIso, offset)

export const findOptionsInContext = (context, key) => {
  const contextOptions = context[key]
  if (!contextOptions || typeof contextOptions !== 'object') return [];

  return contextOptions.map(option => ({
    ...option,
    value: option?.id,
    label: option?.descr,
  }))
}

export const findValuesInContext = (context, key) => {
  const contextOptions = context[key]
  if (!contextOptions || typeof contextOptions !== 'object') return [];

  return contextOptions
}

export const addDays = (startDate, days) => {
  var initialDate = startDate.getDay();
  var daysToAdd = parseInt(days);
  if (initialDate === 0)
    daysToAdd++;
  if (initialDate + daysToAdd >= 6) {
    var remainingWorkDays = daysToAdd - (5 - initialDate);

    daysToAdd += 2;
    if (remainingWorkDays > 5) {
      daysToAdd += 2 * Math.floor(remainingWorkDays / 5);
      if (remainingWorkDays % 5 === 0)
        daysToAdd -= 2;
    }
  }
  startDate.setDate(startDate.getDate() + daysToAdd);
  return startDate;
}

export const titleize = (string, separator = ' ') => {
  return string
    ?.split(separator)
    ?.map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
    ?.join(separator)
}