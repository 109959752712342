import React from 'react'
import parseISO from 'date-fns/parseISO'
import formatISO from 'date-fns/formatISO'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import deLocale from 'date-fns/locale/pt-BR'

function isValidDate(d) {
  return d instanceof Date && !isNaN(d)
}

export function DateField({ name, value, onChange, ...props }) {
  const handleChange = (newDate) => {
    onChange({
      target: { name, value: isValidDate(newDate) ? formatISO(newDate) : null },
    })
  }

  const parsedValue = value ? parseISO(value) : null

  return (
    <MuiPickersUtilsProvider locale={deLocale} utils={DateFnsUtils}>
      <KeyboardDatePicker
        name={name}
        margin="normal"
        format="dd/MM/yyyy"
        value={parsedValue}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        onChange={handleChange}
        autoOk={true}
        cancelLabel="Cancelar"
        {...props}
      />
    </MuiPickersUtilsProvider>
  )
}
