import { useEffect } from 'react'
import { darken } from 'polished'
import PropTypes from 'prop-types'
import theme from '../../theme'

const css = `
@page {
  size: A4;
  margin: 12px;
}
* {
  margin: 0;
  padding: 0;
  font-size: 0.85rem;
  box-sizing: border-box;
  font-family: 'Roboto Slab', serif;
}
.root {
  display: flex;
  width: 100%;
  flex-direction: column;
}
table {
  page-break-inside:auto 
  break-after: page !important;
  border-collapse: collapse;
  border-spacing: 0;
}
tr { 
  page-break-inside:avoid; 
  page-break-after:auto;
}
thead {
    display:table-header-group;
}
tfoot {
  display:table-footer-group;
}     
svg {
  display: none;
}
svg[show='true'] {
  display: block;
}
b {
  padding-right: 3px;
}
table thead tr.sticky-header, table thead tr.normal-header {
  background: ${darken(0.02, theme.palette.background.default)};
}
table thead tr {
  text-align: left;
}
table tbody tr {
  border-color: ${theme.palette.background.default};
  border-style: solid;
  border-width: 0.3px 0;
}
table tfoot tr {
  border:none;
}
table tbody tr td .hidden-in-print,
table thead tr th .hidden-in-print,
table thead tr th[action='true'],        
table tbody tr td[action='true'],
table tfoot tr td[action='true'] {
  display:none;
}
table thead tr th,
table tbody tr td,
table tfoot tr td {
  padding: 7px;
  white-space: nowrap;
}
table tbody tr td[flagged='true'] {
  text-align:center;
}
table tbody tr td[flagged='true'] span {
  display: none;
}
table tbody tr td[flagged='true']::after {
  content: ' ✓';
}
table thead tr th[type='decimal'], 
table tbody tr td[type='decimal'], 
table tfoot tr td[type='decimal'] {
  text-align: right;
}
.align-right {
  text-align: right;
}
table tfoot tr td[type='decimal'] div {
  padding: 0 10px;
}
table tfoot tr td {
  font-weight: 600;
}
.report-root th,
.report-root td {
  padding-left: 0;
  padding-right: 0;
}
.report-content {
  margin-bottom: 10px;
}
.report-content .report-row {
  padding: 5px 0;
  display:flex;
  justify-content:space-between;
  align-items:center;
}
.report-content .report-row span {
  font-weight: 300;
  flex: 1;
}
.report-content .report-row span.report-title {
  font-size: 1.1rem;
  font-weight: 600;
}
.report-content .report-row span:last-child {
  text-align: right;
  display:flex;
  justify-content:flex-end;
}
.Rep-fill {
  background-color: ${darken(0.0009, theme.palette.background.default)};
} 
.Rep-direction-row {
  display:flex;
  flex-direction:row;
}
div.Rep-section {
  flex: 1;
}
.Rep-row {
  display:flex;
}
.Rep-row-label {
  flex: 1;
  font-weight: 600;
  max-width: 80px;
}
.Rep-row-label::after {
  content: ':'
}
.Rep-row-value {
  font-weight: 400;
}
.Rep-justify-between {
  justify-content:space-between;
}
.Rep-justify-evenly {
  justify-content:space-evenly;
}
.Rep-justify-around {
  justify-content:space-around;
}
.Rep-padding-small {
  padding: 10px;
}
.Rep-section-label {
  font-weight: 600;
  font-size: 13px;
  margin-bottom: 10px;
}

.report-container {
  width: 100%;
  margin-top: -3rem;
}


.root-container {
  display: flex;
  flex-direction: column;
  font-size: 15px;
  width: 100%;
  margin-top: 30px;
}
.root-container h6 {
  display: flex;
  padding: 10px;
}
.root-container h6 strong {
  margin-right: 5px;
}
.header {
  display: flex; 
  justify-content: space-between; 
  align-items: center;
  padding: 5px;
  margin-right: 10px;
}
.img {
  margin-left: 10px;
}
.span {
  display: flex;
  justify-content: center;
  font-size: 20px;
  color: #fff;
  background-color: #808080;
}
.data-product {
  display: flex;
  flex-direction: column;
  padding: 10px;
}
.div-products {
  display: flex;
  justify-content: space-around;
  padding: 10px 0px;
  width: 100%;
}
.data-order {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 10px;
  width: 70%;
}
.data-customer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 10px;
  width: 100%;
}
.repRowLabel {
  margin-right: 8px;
  font-weight: 600;
  max-width: 150px;
}
`

const generateReport = async (data, title) => {

  await data

  const html = `
    <html>
      <head>
        <title>
          ${title}
        </title>
        <link rel="preconnect" href="https://fonts.gstatic.com">
        <link href="https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap" rel="stylesheet" />
        <meta charset="utf-8" />
      </head>
      <body>
        <style width: 100%>
        ${css}
        </style>
        <div class="root">
          ${(data?.innerHTML ?? 'Não foi possivel gerar o relatório!')}
      </body>
  </html>`
  return html
}

export function Report({
  title,
  data,
  print,
}) {

  useEffect(() => {
    if (print !== null) {
      const printWindow = async () => {

        const newWindow = window.open('about:blank')
        const html = await generateReport(data, title)

        newWindow.document.write(html)
        setTimeout(() => {
          newWindow.print()
          newWindow.close()
        }, 500)
      }

      printWindow()
    }
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [print])

  return null
}

export default Report

Report.propTypes = {
  title: PropTypes.string,
  data: PropTypes.object,
  print: PropTypes.oneOf([null, true, false])
}
Report.defaultProps = {
  title: '',
  data: {},
  print: null
}