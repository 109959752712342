import styled from 'styled-components'
import theme from '../../theme'
import { darken, lighten } from 'polished'
import {
  TableContainer as MuiTableContainer,
  Table as MuiTable,
  TableRow as MuiTableRow,
  TableHead as MuiTableHead,
  TableBody as MuiTableBody,
  TableFooter as MuiTableFooter,
  TableCell as MuiTableCell,
  Avatar as MuiAvatar,
  Chip as MuiChip,
  Box as MuiBox,
  makeStyles,
} from '@material-ui/core'

import { FlagSharp as MuiFlagIcon } from '@material-ui/icons'

export const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    position: 'relative',
  },
  print: {
    // position: 'absolute',
    top: '-2px',
    right: 20,
    zIndex: 10,
  },
  printButton: {
    background: lighten(0.2, theme.palette.secondary.main),
    color: theme.palette.secondary.light,
  },
  link: {
    color: darken(0.1, theme.palette.info.main),
    '&:hover': {
      cursor: 'pointer',
      color: lighten(0.1, theme.palette.info.main),
    }
  },
  hidden: {
    display: 'none',
  },
  tableEmptyBox: {
    display: 'flex',
    justifyContent: 'center',
    margin: `${theme.spacing(1)} 0`,
    padding: theme.spacing(1),
    border: `1.5px solid ${theme.palette.background.default}`,
  },
  noWrap: {
    whiteSpace: 'noWrap',
  },
  small: {
    padding: theme.spacing(0.1),
  },
  medium: {
    padding: theme.spacing(0.2),
  },
  chipColumn: {
    textAlign: 'center',
    width: 107,
    padding: 0,
  },
  actionColumn: {
    width: 53,
  },
  noLabelColumn: {
    textAlign: 'center',
    padding: theme.spacing(0.5),
  },
  large: {
    padding: theme.spacing(0.5),
  },
  avatar: {
    paddingBottom: theme.spacing(0.2),
    opacity: 0.7
  },
  a_small: {
    width: 25,
    height: 25,
    fontSize: theme.spacing(1.8),
  },
  a_large: {
    width: 40,
    height: 40,
    fontSize: theme.spacing(2),
  },
}))

// TABLE COMPONENTS

export const TableContainer = styled(MuiTableContainer)`
  overflow-y: overlay;
  max-height: ${({ scroll, window_inner_height }) => scroll ? window_inner_height : 'auto'};

  .hidden {
    display:none;
  }
`

export const Table = styled(MuiTable)`
  border-collapse:collapse;

  .no-wrap {
    white-space:nowrap;
  }

  .report-root {
    border-bottom: 3px solid #FFF;
  } 

  .report-root th,
  .report-root td {
    padding: 0;
  }

  .report-root td:last-child,
  .report-root th:last-child {
    padding-right: 15px;
  }
  .report-root td,
  .report-root th {
    background-color: ${darken(0.001, theme.palette.background.default)};
  }

  .Rep-direction-row {
    display:flex;
    flex-direction:row;
  }

  div.Rep-section {
    flex: 1;
  }

  .Rep-row {
    display:flex;
  }

  .Rep-row-label {
    flex: 1;
    max-width: 100px;
    font-weight: 600;
  }
  .Rep-row-label::after {
    content: ':'
  }
  .Rep-border {
    border-bottom: 0.3px solid #FFF;
    margin: 2px 0;
  }

  .Rep-justify-between {
    justify-content:space-between;
  }

  .Rep-justify-evenly {
    justify-content:space-evenly;
  }

  .Rep-justify-around {
    justify-content:space-around;
  }

  .Rep-padding-small {
    padding: 10px;
  }

  .Rep-section-label {
    font-weight: 600;
    margin-bottom: 10px;
  }
`

export const TableRow = styled(MuiTableRow)`
  border-left: ${props => props.flag === 'true' && `10px solid ${theme.palette.secondary.main}`};

  border-left-color: ${props => props.error === 'true' && theme.palette.error.main};
  border-left-color: ${props => props.success === 'true' && theme.palette.success.main};

  min-height: 40px;
`

export const TableHead = styled(MuiTableHead)`
  .sticky-header th, .normal-header th {
    background-color: ${theme.palette.background.default};
  }

  .sticky-header th {
    top: -1px;
    left: 0;
    z-index: 2;
    position: sticky;
  }

  th:last-child {
    padding-right: 30px;
  }
`

export const TableBody = styled(MuiTableBody)``

export const TableFooter = styled(MuiTableFooter)`
  display: table-row-group;
`

export const TableCell = styled(MuiTableCell)`
  font-size: ${theme.spacing(1.75)}px;

  @media (max-width: 600px) {
    font-size: ${theme.spacing(1.5)}px;
    white-space: nowrap;
  } 
`

export const ReportHeader = styled(MuiBox)`
  padding: 10px 0;
  margin-bottom: 10px;
  background-color: ${lighten(0.1, theme.palette.background.default)};

  .report-section {
    .report-row {
      display:flex;
      justify-content:space-between;
      align-items:center;
      margin-right: 15px;
      span {
        flex: 1;
      }
      b {
        padding-right: 3px;
      }
      .report-title {
        font-size: 16px;
        font-weight: 600;
      }

      span:last-child {
        text-align: right;
      }
     }
  }
`

// TABLE FLAGS

export const Chip = styled(MuiChip)`
  color: ${theme.palette.secondary.light};
  font-size: ${theme.spacing(1.4)}px;
  width: 109px;
  justify-content: left;
  background-color: ${props => props.background ? props.background : theme.palette.action.disabled};

  .MuiChip-avatar {
    color: ${theme.palette.secondary.light};
  }
`

export const Avatar = styled(MuiAvatar)`
  background-color: ${props => props.background ? props.background : theme.palette.action.disabled};
`

export const Flag = styled(MuiFlagIcon)`
  display: ${props => props.show ? 'block' : 'none'};
  color: ${props => props.flagcolor ?? 'inherit'};
`