import React from 'react'
import NumberFormat from 'react-number-format'
import { TextField } from '@material-ui/core'

export function NumberField(props) {
  const { name, onChange, ...otherProps } = props

  const handleChange = ({ value }) => {
    onChange({ target: { name, value }})
  }

  return (
    <NumberFormat
      name={name}
      thousandSeparator="."
      decimalSeparator=","
      fixedDecimalScale={true}
      customInput={TextField}
      isNumericString={true}
      onValueChange={handleChange}
      {...otherProps}
    />
  )
}
export default NumberField
