import React from 'react'
import PropTypes from 'prop-types'

import { sortByField } from '../helpers'

const SortLink = props =>
  <a href="*" onClick={event => { event.preventDefault(); props.onClick(props.field) }}>
    {props.fieldName}
    &nbsp;
    {props.currentSort.field === props.field && <i className={`fa fa-sort-${props.currentSort.orderAsc ? 'up' : 'down'}`}></i>}
  </a>

// https://blog.logrocket.com/validating-react-component-props-with-prop-types-ef14b29963fc/
SortLink.propTypes = {
  currentSort: PropTypes.shape({
    field: PropTypes.string.isRequired,
    orderAsc: PropTypes.bool.isRequired
  }).isRequired,
  field: PropTypes.string.isRequired,
  fieldName: PropTypes.string,
  onClick: PropTypes.func.isRequired,
}

SortLink.defaultProps = {
}

const changeSort = (field, currentSort, setCurrentSort, setRecords) => {
  const newOrderAsc = currentSort.field === field ? !currentSort.orderAsc : true
  setCurrentSort({ field, orderAsc: newOrderAsc })
  typeof setRecords === 'function' && setRecords(previousRecords => sortByField(previousRecords, field, newOrderAsc))
}

export { SortLink, changeSort }
