import styled from 'styled-components'
import theme from '../../theme'
import { Form } from '../../lib/form'
import { Slide as MuiTransitionComponent } from '@material-ui/core'
import { Paper as MuiPaper } from '@material-ui/core'

export const TransitionComponent = styled(MuiTransitionComponent)`
  width:420px;
  margin-right:10px;

  @media only screen and (max-width: 800px ) {
    width:auto;
    margin-bottom:10px;
    margin-right:0;
  }
`
export const Header = styled.div`
  font-size:20px;
  text-indent:20px;

  padding:5px 0;

  background-color: ${theme.palette.secondary.main};
  color: #FFF;
`
export const Paper = styled(MuiPaper)`
  padding-bottom:10px;
`
export const StyledForm = styled(Form)`
  border:1px solid #eeee;
  padding:20px;
`

export const Buttons = styled.div`
  margin-top:10px;
  margin-left:20px;
  display:flex;
  justify-content:flex-start;

  button {
    margin: ${theme.spacing(0.2)}px;
  }
`