import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import axiosBase from '../../services/api'
import { isEmpty } from 'ramda'
import API from '../../services/api_base'
import { handleAjaxError, checkIfEnterKeyPressed, formatter } from '../../helpers'
import { useForm, TextField, DateField, SelectField } from '../../lib/form'
import {
  TransitionComponent,
  Header,
  Paper,
  StyledForm,
  Buttons
} from './styles'
import { FormControl, Grid } from '@material-ui/core'
import { Button } from '..'

export function Filters({ open, handleFiltering, activeFilters }) {
  const ORDER_STATUS_OPTIONS = [
    { value: 'unfulfilled', label: 'Abertos' },
    { value: 'canceled', label: 'Cancelados' },
    { value: 'fulfilled', label: 'Baixados' }
  ]

  const defaultScope = ORDER_STATUS_OPTIONS[0].value

  const [billingCompanies, setBillingCompanies] = useState([])
  const form = useForm()

  useEffect(() => {

    if (isEmpty(activeFilters)) {
      form.setValues({ order_scope: defaultScope, })
    } else {
      form.setValues({
        ...activeFilters,
        ped_data_emissao: formatter(activeFilters.ped_data_emissao).ISO()
      })
    }

    if (open) {
      if (billingCompanies.length === 0) {
        const updateBillingCompanies = (newCompanies) => {
          setBillingCompanies(
            newCompanies.map((company) => ({
              ...company,
              value: company.id,
              label: company.descr,
            }))
          )
        }

        axiosBase
          .get([API.miscellaneous, 'billing_companies'].join('/'))
          .then((response) => response.data)
          .then(updateBillingCompanies)
          .catch(handleAjaxError)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, activeFilters])

  const handleDinamycFilter = values => event => {
    if (checkIfEnterKeyPressed(event)) handleFilters(values)

    return false
  }

  const handleFilters = values => {
    const emissionDate = values.ped_data_emissao

    const newValues = {
      ...values, ped_data_emissao: emissionDate && formatter(emissionDate).toDayOnly()
    }
    handleFiltering(newValues)
  }

  const clearFilters = () => {
    form.setValues({ order_scope: defaultScope })

    const clearFilterCondition =
      Object.keys(form.values).length > 1
      && !isEmpty(activeFilters)

    if (clearFilterCondition) handleFiltering({})
  }

  return (
    <TransitionComponent direction='right' in={open} mountOnEnter unmountOnExit>
      <Paper>
        <Header>
          Filtros
        </Header>
        <StyledForm form={form} onKeyUp={handleDinamycFilter(form.values)}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  name='cod_pedido'
                  label='Pedido'
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <TextField
                  name='cod_cliente'
                  label='CNPJ'
                />
              </FormControl>
            </Grid>
            <Grid item xs={8}>
              <FormControl fullWidth>
                <TextField
                  name='emp_empresa.emp_razao_social'
                  label='Razão Social'
                  splitter=','
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <SelectField
                  name='vnd_pedidos.id_cia'
                  label='Companhia'
                  options={billingCompanies}
                  disabled={billingCompanies.length === 0}
                  emptyOption='-- Selecione --'
                  splitter=','
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <SelectField
                  name='order_scope'
                  label='Status'
                  options={ORDER_STATUS_OPTIONS}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <DateField
                  name='ped_data_emissao'
                  label='Emissão'
                />
              </FormControl>
            </Grid>
          </Grid>
        </StyledForm>
        <Buttons>
          <Button
            label='Filtrar'
            background='info'
            onClick={() => handleFilters(form.values)}
          />
          <Button
            background='error'
            label='Limpar'
            onClick={clearFilters}
          />
        </Buttons>
      </Paper>
    </TransitionComponent>
  )
}

Filters.propTypes = {
  open: PropTypes.bool.isRequired,
  handleFiltering: PropTypes.func.isRequired,
}

Filters.defaultProps = {}

export default Filters