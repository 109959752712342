import React from 'react'
import { TableSortLabel } from '@material-ui/core'

export function SortLabel({ name, label, currentSort, onClick }) {

  const handleClick = () => onClick(name)

  const active = currentSort.field === name

  const direction = currentSort.orderAsc ? 'asc' : 'desc'

  return (
    <TableSortLabel active={active} direction={direction} onClick={handleClick}>
      {label}
    </TableSortLabel>
  )
}

export default SortLabel
