import React, { useEffect, useState, useContext } from "react";
//Services
import PropTypes from "prop-types";
import axiosBase from "../../services/api";
import API from '../../services/api_base'
import Context from "../../helpers/context";
import { CONTEXT_KEYS } from "../../consts";
//Helpers
import ErrorMessage from "../../components/error_message";
import {
  formatter,
  handleAjaxError,
  isEmpty,
  findValuesInContext,
} from "../../helpers";
import {
  useForm,
  TextField,
  AutocompleteField,
  DateField,
  SelectField,
  CheckboxField,
} from "../../lib/form";
import { useRepresentative } from "../../hooks";
//Components and styles
import { Form } from "./styles";
import { Modal } from "../../components";
import { FormControl, Grid, Box } from "@material-ui/core";
import theme from "../../theme";
export default function OrderForm(props) {
  const { show, onClose, onSave, inSaving, recordConfig } = props;
  const apiContext = useContext(Context);
  const editable = recordConfig.isEditable;

  const form = useForm();

  const representative = useRepresentative();
  const [customers, setCustomers] = useState([]);
  const [customersDeliveryTo, setCustomersDeliveryTo] = useState([]);
  const [itineraries, setItineraries] = useState([]);
  const [showModalAlert, setShowModalAlert] = useState(false);
  const [showModalTriang, setShowModalTriang] = useState(false);
  const [isTriangular, setIsTriangular] = useState(false);
  const [minDate, setMinDate] = useState(new Date());
  const [holidays, setHolidays] = useState([]);

  const VARIATION_TYPES_OPTIONS = [
    { value: "+-", label: "+/-" },
    { value: "+" },
    { value: "-" },
  ];
  const FREIGHT_OPTIONS = [{ value: "CIF" }, { value: "FOB" }];

  const handleStateModalAlert = () => setShowModalAlert((state) => !state);

  const handleIsTriangular = (isTriangular) => {
    form.values.customer.cli_triangular = isTriangular;
    setIsTriangular(isTriangular);
    setShowModalTriang(false);
  };

  useEffect(() => {
    form.setErrors({});
    form.setValues({});
    if (show) {
      if (isEmpty(recordConfig.url)) {
        form.setValues({
          billing_company: { id: null },
          customer: { id: null },
          payment_condition: { id: null },
          delivery_address: { id: null },
          id_roteiro: null,
          ped_fat_individual: false,
          ped_tipo_variacao: VARIATION_TYPES_OPTIONS[0].value,
          ped_tipo_frete: FREIGHT_OPTIONS[0].value,
        });
      } else {
        const updateOrder = ({ customer, ...order }) => {
          const formValues = {
            ...order,
            billing_company: {
              id: order.billing_company?.id,
            },
            customer: {
              id: customer?.id,
              emp_razao_social: customer?.emp_razao_social,
              cli_triangular: customer?.cli_triangular,
            },
            payment_condition: {
              id: order.payment_condition?.id.toString(),
            },
            delivery_address: {
              id: order.delivery_address?.id,
            },
            id_roteiro: order.id_roteiro,
            ped_data_recebimento: formatter(
              formatter(order.ped_data_recebimento).toDayOnly()
            ).ISO(),
          };
          form.setValues(formValues);
        };

        axiosBase
          .get(recordConfig.url)
          .then((response) => response.data)
          .then(updateOrder)
          .catch(handleAjaxError);
      }
    }

    if (customers.length === 0) {
      const customersOptions = findValuesInContext(
        apiContext,
        CONTEXT_KEYS.customers
      ).map((customer) => ({
        ...customer,
        value: customer.id,
        label: `${
          customer.emp_razao_social
        } - ${customer.emp_cidade.trim()} (${formatter(
          customer.id
        ).toCNPJOrCPF()})`,
      }));

      setCustomers(customersOptions);
    }

    if (customersDeliveryTo.length === 0) {
      const customersDeliveryToOptions = findValuesInContext(
        apiContext,
        CONTEXT_KEYS.customer_delivery_to
      ).map((customer_delivery_to) => ({
        ...customer_delivery_to,
        value: customer_delivery_to.customer.id,
        label: `${
          customer_delivery_to.customer.emp_razao_social
        } - ${customer_delivery_to.customer.emp_cidade.trim()} (${formatter(
          customer_delivery_to.customer.id
        ).toCNPJOrCPF()})`,
      }));

      setCustomersDeliveryTo(customersDeliveryToOptions);
    }

    if (itineraries.length === 0) {
      const itinerariesOptions = findValuesInContext(
        apiContext,
        CONTEXT_KEYS.itineraries
      ).map((itineraries) => ({
        ...itineraries,
        value: itineraries.id_roteiro,
        label: `${itineraries.dsc_roteiro}`,
      }));

      setItineraries(itinerariesOptions);
    }

    axiosBase
      .get([API.miscellaneous, 'min_delivery_date'].join('/'))
      .then((response) => setMinDate(response.data.min_date))
      .catch(handleAjaxError);

    axiosBase
      .get([API.miscellaneous, 'holidays'].join('/'))
      .then((response) => setHolidays(response.data.holidays.map((h) => formatter(h).toDayOnly())))
      .catch(handleAjaxError);      

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordConfig, show]);

  const findSelectedCustomer = () => {
    const { customer } = form.values;

    if (!(customer && customer.id !== undefined)) return;

    const selected = customers.find((cust) => cust.id === customer.id);

    return selected;
  };

  const selectedCustomer = findSelectedCustomer();

  const findBillingCompanies = () => {
    const { billing_company: billingCompany } = form.values;

    const companies = selectedCustomer
      ? selectedCustomer.billing_companies
      : [];

    // Reset the billing company in form if current selected option does not
    // belong to the selected customer's billing companies.
    if (
      billingCompany &&
      billingCompany.id !== null &&
      !companies.find((company) => company.id === billingCompany.id)
    ) {
      form.setFieldValue("billing_company.id", null);
    }

    return companies;
  };

  const billingCompanies = findBillingCompanies();

  const findSelectedBillingCompany = () => {
    const { billing_company: billingCompany } = form.values;

    if (!(billingCompany && billingCompany.id !== undefined)) return;

    const companies = selectedCustomer
      ? selectedCustomer.billing_companies
      : [];

    const selected = companies.find((comp) => comp.id === billingCompany.id);

    return selected;
  };

  const selectedBillingCompany = findSelectedBillingCompany();

  const setItinerary = () => {
    if (show && selectedBillingCompany) {
      const commercial_relationships = selectedCustomer
        ? selectedCustomer.commercial_relationships
        : [];

      const itinerary = commercial_relationships.find(
        (comm) =>
          comm.id.toString() ===
          [selectedCustomer.id, selectedBillingCompany.id].join(",")
      );

      if (form.values.cod_cliente_entrega) {
        const roteiro = customersDeliveryTo.find(
          (rot) => rot.customer.id === form.values.cod_cliente_entrega
        );
        roteiro && form.setFieldValue("id_roteiro", roteiro.id_roteiro);
      } else {
        itinerary &&
          form.setFieldValue(
            "id_roteiro",
            form.values.id_roteiro || itinerary.id_roteiro
          );
      }
    }
  };

  const findSelectedItinerary = () => {
    const { id_roteiro } = form.values;

    if (!id_roteiro) return;

    const selected = itineraries.find((comp) => comp.id_roteiro === id_roteiro);
    form.values.ped_dias_transito = selected.crf_dias_transito;
    return selected;
  };

  findSelectedItinerary();

  useEffect(() => {
    if (selectedCustomer?.cli_triangular) {
      if (form.values.cod_cliente_entrega == null) {
        setShowModalTriang(true);
      }
      setIsTriangular(true);
    } else {
      setShowModalTriang(false);
      setIsTriangular(false);
      form.setFieldValue("cod_cliente_entrega", null);
    }

    if (selectedCustomer) {
      form.values.customer.emp_razao_social = selectedCustomer.emp_razao_social;
      form.values.customer.cli_triangular = selectedCustomer.cli_triangular;
      setItinerary();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCustomer]);

  useEffect(() => {
    if (selectedBillingCompany) setItinerary();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBillingCompany]);

  const paymentConditions = () => {
    const {
      billing_company: billingCompany,
      payment_condition: paymentCondition,
    } = form.values;

    const selectedBillingCompany =
      billingCompany &&
      billingCompanies.find((company) => company.id === billingCompany.id);

    const paymentConditions = selectedBillingCompany
      ? selectedBillingCompany.active_payment_conditions
      : [];

    // Reset the payment condition in form if current selected option does not
    // belong to the selected customer's conditions.
    if (
      paymentCondition &&
      paymentCondition.id !== null &&
      !paymentConditions.find(
        (condition) => condition.id.toString() === paymentCondition.id
      )
    ) {
      form.setFieldValue("payment_condition.id", null);
    }

    return paymentConditions;
  };

  const deliveryAddresses = () => {
    const { delivery_address: address } = form.values;
    //const selectedCustomer = findSelectedCustomer()

    const deliveryAddresses = selectedCustomer
      ? selectedCustomer.delivery_addresses
      : [];

    // Reset the delivery address in form if current selected option does not
    // belong to the selected customer's addresses.
    if (
      address &&
      address.id !== null &&
      !deliveryAddresses.find((addr) => addr.id === address.id)
    ) {
      form.setFieldValue("delivery_address.id", null);
    }

    return deliveryAddresses;
  };

  const billingCompaniesOptions = billingCompanies.map((company) => ({
    value: company.id,
    label: company.descr,
  }));

  const paymentConditionsOptions = paymentConditions().map((condition) => ({
    value: condition.id.toString(),
    label: condition.descr,
  }));

  // NOT SERIALIZE COMMERCIAL_RELATIONSHIPS

  const avaiblePaymentConditionsOptions = paymentConditionsOptions.filter(
    (condition) => {
      const associatedPaymentConditions =
        selectedCustomer &&
        selectedCustomer.commercial_relationships.map((relation) => {
          return relation.rep_cod_empresa === representative.id
            ? relation.associated_payment_condition
            : false;
        });

      return associatedPaymentConditions.includes(condition.value)
        ? condition
        : false;
    }
  );

  const deliveryAddressesOptions = deliveryAddresses().map((addr) => ({
    value: addr.id,
    label: addr.descr,
  }));

  const { ped_data_recebimento: receiptDate } = form.values;
  const selectedDate = formatter(receiptDate).toDayOnly();

  const handleSaveForm = (current) => {
    setItinerary();

    if (isEmpty(receiptDate)) {
      onSave(current);
    } else {
      handleStateModalAlert();
    }
  };

  const handleConfirm = (current) => {
    handleStateModalAlert();
    onSave(current);
  };

  const title = form.values.id
    ? "Pedido " +
      form.values.cod_pedido +
      " ( " +
      form.values.customer.emp_razao_social +
      " )"
    : "Novo Pedido";

  //const minDate = addDays(new Date(), 3 + selectedItinerary?.crf_dias_transito);

  function disableDates(date) {  
    return [0,6].includes(date.getDay()) ||  holidays.includes(date.toLocaleDateString('pt-br'));
  }

  return (
    <Modal
      size="sm"
      open={show}
      title={title}
      onAction={handleSaveForm}
      inAction={inSaving}
      current={form}
      onClose={onClose}
      actionProps={{
        label: "Salvar",
        show: editable,
        background: "info",
      }}
      fullWidth
      content={
        <>
          <Modal
            open={showModalAlert}
            current={form}
            onClose={handleStateModalAlert}
            onAction={handleConfirm}
            actionProps={{
              label: "Confirmar",
              background: "success",
            }}
            title={`Confirmação - ${title}`}
            content={
              <Box fontSize={15}>
                {" "}
                Deseja confirmar a entrega para <strong>
                  {selectedDate}
                </strong>{" "}
                ?{" "}
              </Box>
            }
          />
          <Modal
            open={showModalTriang}
            current={form}
            onClose={() => handleIsTriangular(false)}
            onAction={() => handleIsTriangular(true)}
            actionProps={{
              label: "Sim",
              background: "success",
            }}
            closeProps={{
              label: "Não",
              background: "error",
            }}
            title="Atenção!"
            content={
              <Box fontSize={15}>
                {" "}
                Essa é uma venda <strong>Triangular</strong> ?{" "}
              </Box>
            }
          />
          <Form form={form}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <AutocompleteField
                    name="customer.id"
                    options={customers}
                    size="sm"
                    label="Cliente"
                  />
                  <ErrorMessage form={form} attr="customer" />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <SelectField
                    name="billing_company.id"
                    options={billingCompaniesOptions}
                    size="sm"
                    label="Faturar"
                    emptyOption="-- Selecione --"
                  />
                  <ErrorMessage form={form} attr="billing_company" />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <SelectField
                    name="ped_tipo_variacao"
                    options={VARIATION_TYPES_OPTIONS}
                    size="sm"
                    label="Tipo de variação"
                    emptyOption="-- Selecione --"
                  />
                  <ErrorMessage form={form} attr="ped_tipo_variacao" />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <TextField name="ped_variacao" label="Variação Permitida" />
                  <ErrorMessage form={form} attr="ped_variacao" />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <SelectField
                    name="ped_tipo_frete"
                    options={FREIGHT_OPTIONS}
                    size="sm"
                    label="Frete"
                    emptyOption="-- Selecione --"
                  />
                  <ErrorMessage form={form} attr="ped_tipo_frete" />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <TextField name="ped_seupedido" label="Seu Pedido" />
                  <ErrorMessage form={form} attr="ped_seupedido" />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <AutocompleteField
                    name="payment_condition.id"
                    options={avaiblePaymentConditionsOptions}
                    size="sm"
                    label="Condição de Pagamento"
                  />
                  <ErrorMessage form={form} attr="payment_condition" />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <SelectField
                    name="delivery_address.id"
                    options={deliveryAddressesOptions}
                    size="sm"
                    label="Entregar"
                    emptyOption="-- Selecione --"
                  />
                  <ErrorMessage form={form} attr="delivery_address" />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                {isTriangular && (
                  <FormControl fullWidth>
                    {customersDeliveryTo.length > 0 && (
                      <AutocompleteField
                        name="cod_cliente_entrega"
                        options={customersDeliveryTo}
                        size="sm"
                        label="(Venda Triangular) Entregar em"
                        variant="filled"
                        variant_color={theme.palette.secondary.lighter}
                      />
                    )}
                    <ErrorMessage form={form} attr="cod_cliente_entrega" />
                  </FormControl>
                )}
              </Grid>
              <Grid item xs={8}>
                <FormControl fullWidth>
                  {!isTriangular && (
                    <AutocompleteField
                      name="id_roteiro"
                      options={itineraries}
                      size="sm"
                      label="Roteiro"
                    />
                  )}
                  <ErrorMessage form={form} attr="id_roteiro" />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <TextField
                    disabled={true}
                    name="ped_dias_transito"
                    label="Dias em Trânsito"
                  />
                  <ErrorMessage form={form} attr="ped_dias_transito" />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <DateField
                    minDate={minDate}
                    minDateMessage=""
                    name="ped_data_recebimento"
                    label="Data de Recebimento"
                    shouldDisableDate={disableDates} 
                  />
                  <ErrorMessage form={form} attr="ped_data_recebimento" />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    multiline
                    name="ped_obs"
                    rows="3"
                    label="Observações"
                  />
                  <ErrorMessage form={form} attr="ped_obs" />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <CheckboxField
                    name="ped_fat_individual"
                    label="Faturamento individual"
                    size="small"
                  />
                  <ErrorMessage form={form} attr="ped_fat_individual" />
                </FormControl>
              </Grid>
            </Grid>
          </Form>
        </>
      }
    />
  );
}

OrderForm.propTypes = {
  recordConfig: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

OrderForm.defaultProps = {};
