import {
  withStyles,
  Box as MuiBox
} from '@material-ui/core'

export const FloatBox = withStyles(theme => ({
  root: {
    background: theme.palette.background.paper,
    boxShadow: theme.shadows[6],
    borderRadius: theme.spacing(1),

    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',

    maxWidth: 700,

    marginLeft: 'auto',
    marginRight: 'auto',
  }
}))(MuiBox)

export const LoginBox = withStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',

    padding: theme.spacing(3),

    '& > .form-area': {

      '& .MuiFormControl-root': {
        marginTop: theme.spacing(1.5),
      },

      '& .MuiButtonBase-root': {
        height: theme.spacing(7),
        fontSize: theme.spacing(2),
        marginTop: theme.spacing(2),
      },
    },
  }
}))(MuiBox)

export const Footer = withStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    padding: theme.spacing(2),

    background: theme.palette.grey[200],

    '& img': {
      maxWidth: theme.spacing(5),
    }
  }
}))(MuiBox)
export const Links = withStyles(theme => ({
  root: {
    display: 'none',
    flexDirection: 'column',

    marginTop: theme.spacing(1),
  }
}))(MuiBox)