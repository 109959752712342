import React from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import parse from 'autosuggest-highlight/parse'
// import match from 'autosuggest-highlight/match'
import diacritic from 'diacritic'

function customMatch(text, query) {
  if (!('string' === typeof query && 'string' === typeof text)) {
    return []
  }

  let t = text.toLowerCase()
  let q = query.trim().toLowerCase()
  const ql = q.length

  if (!(0 < ql && 0 < t.length)) {
    return []
  }

  t = diacritic.clean(t)
  q = diacritic.clean(q)

  let r = []

  let i = t.indexOf(q)
  while (i !== -1) {
    r.push([i, i + ql])
    i = t.indexOf(q, i + ql)
  }

  return r
}

export function AutocompleteField({
  value,
  options,
  name,
  onChange,
  onControlChange,
  label,
  disabled,
  variant,
  variant_color,
  ...props
}) {
  const handleChange = (_event, option) => {
    if (typeof onControlChange === 'function') onControlChange(option?.value, name)
    onChange({
      target: { name, value: option && option.value },
    })
  }

  return (
    <Autocomplete
      disabled={disabled}
      options={options}
      getOptionLabel={(option) => option.label || option.value}
      getOptionSelected={(option, value) => option.value === value.value}
      renderInput={(params) => (
        <TextField style={{ backgroundColor: variant ? variant_color : '' }} variant={variant} label={label} fullWidth {...params} />
      )}
      onChange={handleChange}
      value={
        (value !== undefined &&
          options.find((option) => option.value === value)) ||
        null
      }
      noOptionsText="Nenhum valor foi encontrado"
      autoSelect={true}
      clearOnEscape={true}
      renderOption={(option, { inputValue }) => {
        const effectiveLabel = option.label || option.value

        if (options.length > 1000) return effectiveLabel

        const matches = customMatch(effectiveLabel, inputValue)
        const parts = parse(effectiveLabel, matches)

        return (
          <div>
            {parts.map((part, index) => (
              <span
                key={index}
                style={{
                  fontWeight: part.highlight ? 700 : 400,
                  color: part.highlight ? 'green' : 'black',
                }}
              >
                {part.text}
              </span>
            ))}
          </div>
        )
      }}
    />
  )
}
