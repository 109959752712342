// --> LIBRARIES
import React, { useState } from 'react'
import axios from '../../services/api'
// --> APPLICATION SERVICES
import { log as saveToken } from '../../services/auth'
import { handleAjaxError, handleError, isEmpty } from '../../helpers'
// --> COMPONENTS
import logoEcosis from '../../assets/images/logo_ecosis.jpg'
import { FormControl } from '@material-ui/core'
import { Form, useForm, TextField } from '../../lib/form'
import { LoaderButton, Alert, ErrorMessage } from '../../components'
import { FloatBox, LoginBox, Footer } from './styles'
import { Logo } from '../../components/company_logo'

// eslint-disable-next-line no-unused-vars
const UNAUTHORIZED = 401

const ERRORS = [
  '',
  'Preencha todos os campos para continuar',
  'Preencha o campo de usuário para continuar',
  'Preencha o campo senha para continuar',
]

const handleUnauthorized = (form) => error => {
  const status = error.response.status
  const attr = error.response.data.error.user_session

  if (status === UNAUTHORIZED)
    form?.setErrors({ [attr]: attr === 'user' ? 'Usuário não autorizado' : 'A senha está incorreta' })

  throw error
}

export function Login() {
  const form = useForm()
  const [processing, setProcessing] = useState(false)
  
  const handleProccess = () => setProcessing(state => !state)
  
  const login = (values) => (_event) => {
    form.setErrors({})
    handleProccess()

    let status

    const hasUser = isEmpty(values.cod_empresa)
    const hasPassword = isEmpty(values.emp_senha)

    if (hasUser && hasPassword) {
      status = 1
    } else if (hasUser) {
      status = 2
    } else if (hasPassword) {
      status = 3
    } else status = 0

    if (status === 0) {
      axios
        .post('/session', values)
        .then(response => response.data.auth_token)
        .then(token => {
          saveToken(token)
          window.location.href = '/home'
        })
        .catch(handleUnauthorized(form))
        .catch(handleAjaxError)
        .finally(handleProccess)
    } else {
      handleError(ERRORS[status])
      handleProccess()
    }
  }

  const flexMargin = Math.floor(window.innerHeight / 160)

  return (
    <>
      <FloatBox marginTop={flexMargin}>
        <Alert></Alert>
        <LoginBox>
          <Logo
            width={300}
            height={130}
          />
          <Form form={form} className='form-area'>
            <FormControl fullWidth>
              <TextField
                label='Usuário'
                name='cod_empresa'
                variant='outlined'
              />
              <ErrorMessage form={form} attr='user' />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                label='Senha'
                name='emp_senha'
                type='password'
                variant='outlined'
              />
              <ErrorMessage form={form} attr='password' />
            </FormControl>
            <LoaderButton
              styles={{marginTop: '22px'}}
              fullWidth
              label='ENTRAR'
              onClick={login(form.values)}
              inLoading={processing}
            />
          </Form>
        </LoginBox>
        <Footer>
          <div style={{ fontStyle: 'italic' }}>Powered by:</div>
          <img src={logoEcosis} alt='ECOSIS' />
        </Footer>
      </FloatBox>
    </>
  )
}

export default Login