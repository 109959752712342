import React from 'react'
import Proptypes from 'prop-types'
import { Box, IconButton, FormControl, useMediaQuery } from '@material-ui/core'
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons'
import { SelectField } from '../select_field'
import { Paginator, PaginatorActions } from './styles'

export function Pagination({ pagination }) {

  const smallDeviseMedia = useMediaQuery(`(max-width:800px)`)

  const ROWS_PER_PAGE_OPTIONS = [
    { value: 5 },
    { value: 10 },
    { value: 20 },
    { value: 30 },
    { value: 40 },
    { value: 50 },
    { value: 100 },
  ]

  const {
    state: { currentPage, limit, totalRecords },
    changeLimit,
    goToPreviousPage,
    goToNextPage
  } = pagination

  const totalPages = totalRecords ? Math.ceil(parseInt(totalRecords) / limit) : 0

  return (
    <Paginator
      media={smallDeviseMedia ? 'sm' : 'md'}
    >
      {
        !smallDeviseMedia &&
        <Box>
          Registros encontrados: {totalRecords || 0}
        </Box>
      }
      <PaginatorActions>
        <Box className='action'>
          <span className='paginator'>
            Página: {currentPage} de {totalPages}
          </span>
        </Box>

        <Box className='action'>
          <FormControl fullWidth>
            <SelectField
              options={ROWS_PER_PAGE_OPTIONS}
              onChange={event => changeLimit(event)}
              value={limit}
            />
          </FormControl>
        </Box>

        <Box className='action'>
          <IconButton
            className='paginator-menu'
            onClick={goToPreviousPage}
            disabled={currentPage <= 1}
          >
            <ArrowBackIos />
          </IconButton>
          <IconButton
            className='paginator-menu'
            onClick={goToNextPage}
            disabled={currentPage === totalPages || totalPages === 0}
          >
            <ArrowForwardIos />
          </IconButton>
        </Box>

      </PaginatorActions>
    </Paginator>

  )
}

Pagination.propTypes = {
  pagination: Proptypes.object.isRequired,
}

Pagination.defaultProps = {}

export default Pagination