import React from 'react'
import { LinearProgress } from '@material-ui/core'

export function InputLoader() {
  return (
    <div style={{ margin: '15px 0' }}>
      <LinearProgress color='secondary' />
    </div>
  )
}



export default InputLoader