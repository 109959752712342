import React from 'react'
import { Box, makeStyles, Typography } from '@material-ui/core'
import ErrorIcon from '@material-ui/icons/Error'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    height: '100%',
  },
  container: {
    padding: '10px 2px ',
    textAlign: 'center'
  },
  warning: {
    fontSize: '50px',
    color: theme.palette.error.main,
  },
  message: {
    padding: '5px',
  }
}))

export function PageNotFound(){
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Box className={classes.container}>
        <ErrorIcon className={classes.warning}/>
        <Typography component='div' variant='h4'>
          404 - Page Not Found
        </Typography>
        <Typography className={classes.message} component='div' variant='caption'>
          Página não encontrada, verifique o caminho digitado!
        </Typography>
      </Box>
    </div>
  )
}

export default PageNotFound