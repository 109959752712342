import React, { useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Add, ChevronLeft } from '@material-ui/icons'
import { Box, Typography } from '@material-ui/core'

import api from '../../../services/api'
import apiEndPoints from '../../../services/api_base'

import { useForm } from '../../../lib/form'
import usePagination from '../../../hooks/use_pagination'
import { sortByField, handleAjaxError, handleUnprocessableEntity, CannotInsertMore } from '../../../helpers/helpers'

import { AjaxLoader, Button, changeSort, TablePagination, Modal, Table, confirmation } from '../../../components'
import { OrdersPage, HeaderPage } from '../styles'
import PossibleDates from './possibleDates'
import OrderItemsForm from './form'

export function OrderItems() {
  const { id } = useParams()
  const history = useHistory()
  const [order, setOrder] = useState({})
  const [orderItems, setOrderItems] = useState([])
  const [currentItem, setCurrentItem] = useState({})
  const [currentSort, setCurrentSort] = useState({
    field: 'id_itens',
    orderAsc: false,
  })
  const [inProcessing, setInProcessing] = useState(false)
  const [showForm, setShowForm] = useState(false)
  const [showModalAlert, setShowModalAlert] = useState(false)
  const [available, setAvailable] = useState(false)
  const form = useForm()
  const { ped_conferido: isConferred } = order

  const {
    currentPage,
    recordsPerPage,
    changeRecordsPerPage: handleRowsPerPageChange,
    gotoPage: handlePageChange,
    calculateNewPaginatorData,
  } = usePagination()

  const handleFetch = ({ data: order }) => {
    setAvailable(true)
    setOrder(order)

    setOrderItems(
      sortByField(order.items, currentSort.field, currentSort.orderAsc)
    )
  }

  const handleSortChange = (field) => {
    changeSort(field, currentSort, setCurrentSort, setOrderItems)

    handlePageChange(0)
  }

  const { currentPageRecords } = calculateNewPaginatorData(orderItems)

  const handleProcessing = () => setInProcessing(state => !state)

  // orderItem save confirmations
  const handleNewItemConfirmations = async ({form, selectedProduct}) => {

    const itemConfirm = await confirmation.open(
      `Confirmação - Produto`,
      <Typography variant="body1">
        Produto: {selectedProduct?.descr || ''}
      </Typography>
    )

    const quantityConfirm = itemConfirm && await confirmation.open(
      `Confirmação - Quantidade`,
      <Typography variant="body1">
        Quantidade: {form.values?.itped_qtd || ''}
      </Typography>
    )

    const unityValueConfirm = quantityConfirm && await confirmation.open(
      `Confirmação - Valor Unitário`,
      <Typography variant="body1">
        Valor Unitário: {form.values?.itped_preco || ''}
      </Typography>,
    )

    if (unityValueConfirm) {
      const dates = await availableProductionDate();

      if (dates) {
        const confirmReceiveDate = await confirmation.open(
          `Confirmação - Data de Entrega`,
          <Typography variant="body1">
            Data de Entrega: <strong>{dates?.dt_recebimento || ""}</strong>
          </Typography>
        );

        const confirmPossibleDates =
          confirmReceiveDate && await confirmation.open(
            "Atenção!",
            <PossibleDates availableDates={dates} />
        );

        if (confirmReceiveDate && confirmPossibleDates) {
          const newForm = {
            ...form,
            values: {
              ...form.values,
              itped_data_producao: dates.dt_producao,
              order_dates: dates,
            },
          };

          handleSaveForm(newForm);
        }
      }
    }
    
    confirmation.close()
  }

  const availableProductionDate = async () => {
    const order_dates_params = {
      order_id: order.id,
      product_id: form.values.product.id,
      product_qtty: form.values.itped_qtd,
      delivery_date: order.ped_data_recebimento,
    }

    try {
      const response = await api.get([apiEndPoints.miscellaneous, 'order_dates'].join('/'), { params: order_dates_params })
      return response.data

    } catch (error) {
      return null
    }
  }

  const handleDisplayFormForEdit = (record) => (_event) => {
    form.setValues({
      ...record,
      customer_id: order.customer.id,
      billing_company_id: order.billing_company.id,
      product: { id: record.product && record.product.id, descr: record.product && record.product.descr },
      billing_type: { id: record.billing_type && record.billing_type.id, descr: record.billing_type && record.billing_type.descr },
      prd_data_revisao_produto: null,
    })

    form.setErrors({})
    setShowForm(true)
  }
  const limitRecords = orderItems.length >= 1

  const handleDisplayFormForAdd = (_event) => {
    if (limitRecords) return
    form.setValues({
      customer_id: order.customer.id,
      billing_company_id: order.billing_company.id,
      product: { id: null },
      itped_resina_externa: false,
      itped_resina_interna: false,
      prd_data_revisao_produto: null,
    })

    form.setErrors({})
    setShowForm(true)
  }

  const handleStateModalAlert = () => setShowModalAlert(state => !state)

  const handleCloseForm = () => setShowForm(false)

  const handleSaveForm = (form) => {
    if (form.values.prd_data_revisao_produto) {

      const productRevisionDate = form.values.prd_data_revisao_produto ? new Date(Date.parse(form.values.prd_data_revisao_produto)).toLocaleDateString('pt-br') : null
      const orderRevisionDate = form.values.prd_data_revisao ? new Date(Date.parse(form.values.prd_data_revisao)).toLocaleDateString('pt-br') : null

      if ((productRevisionDate && !orderRevisionDate) || (productRevisionDate !== orderRevisionDate)) {
        form.setErrors({
          ...form.errors,
          prd_data_revisao: 'Data de Revisão do Produto incorreta',
        })
        return
      }
    }

    if (!form.values.product.id) {
      form.setErrors({
        ...form.errors,
        product: 'Nenhum Produto selecionado!',
      })
      return
    }

    if (!form.values.billing_type) {
      form.setErrors({
        ...form.errors,
        billing_type: 'Nenhum Tipo de Faturamento selecionado!',
      })
      return
    }

    if (!form.values.itped_qtd) {
      form.setErrors({
        ...form.errors,
        itped_qtd: 'Quantidade inválida!',
      })
      return
    }

    handleProcessing()

    const last_price_params = {
      product_id: form.values.product.id,
      id: order.id,
      itped_onda: form.values.product.itped_onda,
      itped_largura: form.values.product.itped_largura,
      itped_comprimento: form.values.product.itped_comprimento
    }

    api
      .get([apiEndPoints.miscellaneous, 'last_price'].join('/'), { params: last_price_params })
      .then((response) => {

        const last_price = response.data

        if (last_price > 0 && last_price > form.values.itped_preco) {
          form.setErrors({
            ...form.errors,
            itped_preco: `Preço abaixo do permitido ( ${last_price} )`
          })
          handleProcessing()
          return
        }

        if (form.values.id) {
          _updateOrderItem(form)
        } else {
          _createOrderItem(form)
        }

      })
      .catch(handleAjaxError)
  }

  const handleDeleteOrderItem = item => _event => {
    handleStateModalAlert()
    setCurrentItem(item)
  }

  const _deleteOrderItem = item => {
    handleProcessing()
    api
      .delete([apiEndPoints.orders, id, 'order_items', item.id].join('/'))
      .then(handleStateModalAlert())
      .then(_response => {
        setOrderItems(previousRecords => {
          const newRecords = previousRecords.filter(record => (
            record.id !== item.id ? record : false
          ))

          return sortByField(
            newRecords,
            currentSort.field,
            currentSort.orderAsc
          )
        })
      })
      .catch(handleAjaxError)
      .finally(handleProcessing)
  }

  const _createOrderItem = (form) => {
    api
      .post([apiEndPoints.orders, id, 'order_items'].join('/'), {
        order_item: form.values,
      })
      .then((response) => {
        setShowForm(false)

        setOrderItems((previousRecords) => {
          const newRecords = previousRecords.concat(response.data)

          return sortByField(
            newRecords,
            currentSort.field,
            currentSort.orderAsc
          )
        })
      })
      .catch(CannotInsertMore(form))
      .catch(handleUnprocessableEntity(form))
      .catch(handleAjaxError)
      .finally(handleProcessing)
  }

  const _updateOrderItem = (form) => {
    api
      .put([apiEndPoints.orders, id, 'order_items', form.values.id].join('/'), {
        order_item: form.values,
      })
      .then((response) => {
        setShowForm(false)

        setOrderItems((previousRecords) => {
          const newRecords = previousRecords.map((record) =>
            // Notice the ids are actually arrays, so we must convert them into strings for comparison.
            record.id.toString() !== form.values.id.toString()
              ? record
              : response.data
          )

          return sortByField(
            newRecords,
            currentSort.field,
            currentSort.orderAsc
          )
        })
      })
      .catch(handleUnprocessableEntity(form))
      .catch(handleAjaxError)
      .finally(handleProcessing)
  }

  const config = [
    { key: 'id_itens', label: '#' },
    { key: 'product.id', label: 'Código' },
    { key: 'product.descr', label: 'Produto', showSm: true },
    { key: 'itped_peso', label: 'Peso' },
    { key: 'itped_area', label: 'Area' },
    { key: 'itped_preco', label: 'Valor' },
    { key: 'itped_qtd', label: 'Vendido', showSm: true },
    { key: 'itped_qtd_baixada', label: 'Baixada', showSm: true },
    { key: 'itped_qtd_cancelada', label: 'Cancelada', showSm: true },

    { type: 'edit', callback: handleDisplayFormForEdit, showSm: true },
    { type: 'remove', callback: handleDeleteOrderItem, showSm: true },
  ]

  const records = currentPageRecords.map((currentOrderItems) => {
    const { product } = currentOrderItems
    const editable = !isConferred

    return {
      ...currentOrderItems,
      'product.id': product.id,
      'product.descr': product?.descr,
      editable
    }
  })

  return (
    <OrdersPage>
      <HeaderPage>
        <Typography component='div' variant='h6'>
          {`${order.cod_pedido ? order.cod_pedido : 'Pedido'} | Itens`}
        </Typography>
        <AjaxLoader
          url={[apiEndPoints.orders, id].join('/')}
          onFetch={handleFetch}
        />
      </HeaderPage>
      <Table
        columns={config}
        rows={records}
        onSort={handleSortChange}
        currentSort={currentSort}
        flagable={false}
        paginable={false}
        sortable={true}
        smallTable={true}
      />
      <TablePagination
        count={orderItems.length}
        rowsPerPage={recordsPerPage}
        page={currentPage}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleRowsPerPageChange}
      />
      <Box
        padding={1}
        display='flex'
        justifyContent='space-between'
      >
        <Button
          label='Voltar'
          onClick={() => history.goBack()}
          startIcon={<ChevronLeft />}
        />
        {available &&
          <Button
            label='Novo Item'
            background='success'
            onClick={handleDisplayFormForAdd}
            disabled={isConferred || limitRecords}
            startIcon={<Add />}
          />
        }
      </Box>
      <OrderItemsForm
        isConferred={isConferred}
        form={form}
        show={showForm}
        onClose={handleCloseForm}
        onSave={handleNewItemConfirmations}
        inSaving={inProcessing}
      />
      <Modal
        open={showModalAlert}
        onClose={handleStateModalAlert}
        onAction={_deleteOrderItem}
        inAction={inProcessing}
        currentProp='product.descr'
        current={currentItem}
        type='ITEM'
        actionProps={{
          label: 'Excluir',
          background: 'info'
        }}
      />
    </OrdersPage>
  )
}

OrderItems.propTypes = {}

OrderItems.defaultProps = {}

export default OrderItems
