import React, { useEffect, useState, useRef } from 'react'
import axiosBase from '../../services/api'
import PropTypes from 'prop-types'
// --> APPLICATION SERVICES
import { formatter, handleAjaxError } from '../../helpers'
// --> COMPONENTS
import { Button, Modal } from '../../components'
import { Box, CircularProgress } from '@material-ui/core'
import Report from '../../components/Report'
import { ReportCOntainer } from './styles'
import { ReportLogo } from '../../components/company_logo'
import API from '../../services/api_base'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'

export default function OrderReport({ show, onClose, current }) {

  const html = useRef()
  const [order, setOrder] = useState({})
  const [inFetching, setInFetching] = useState(false)
  const [print, setPrint] = useState(null)

  const handleBooleanState = setter => setter(state => !state)

  const handlePrint = () => handleBooleanState(setPrint)
  const handleFetching = () => handleBooleanState(setInFetching)

  useEffect(() => {
    if (!show) {
      setPrint(null)
      return
    }

    const fetchRows = async () => {
      await handleFetching()

      try {
        const response = await axiosBase.get([API.orders, current.id, "show_for_print"].join('/'))
        const newOrder = await response.data
        setOrder(newOrder)
        handleFetching()
      } catch (error) {
        handleAjaxError(error)
        handleFetching()
      }
    }
    if (current.id?.join('') !== order.id?.join(''))
      fetchRows()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])

  const { id, customer, items } = order

  const title = `Pedido de Venda (${(id?.[0] ?? '...')})`
  const subtitle = `Emitido em: ${new Date().toLocaleDateString('pt-br')}`

  const [customerId, customerCompany, customerAddress, cep, deliveryAddr, uf, street, district, tell, contact] = [
    formatter(customer?.id).toCNPJOrCPF(),
    customer?.emp_razao_social,
    `${customer?.emp_cidade}`,
    customer?.zip_code?.id,
    customer?.delivery_addresses?.[0]?.descr,
    customer?.dsc_uf,
    customer?.emp_endereco,
    customer?.emp_bairro,
    customer?.emp_telefone,
    customer?.emp_cont_nome,
    customer?.dsc_empresa
  ]

  const mapToItems = items?.map((value) => (
    {
      desconto: value.itped_desconto,
      peso: value.itped_peso,
      papelao: value.cod_papelao,
      qtd: value.itped_qtd,
      qtd_cancelada: value.itped_qtd_cancelada,
      qtd_baixada: value.itped_qtd_baixada,
      preco: value.itped_preco,
      codigo: value.product.id,
      produto: value.product.descr,
      situacao: value.order_item_status,
      data_prod: value.itped_data_producao,
      tipo: value.product.product_trimbox.DecricaoModelo,
      versao: value.product.product_trimbox.Versao,
      faca: value.product.product_trimbox.Faca,
      cor1: value.product.product_trimbox.Cor1,
      cor2: value.product.product_trimbox.Cor2,
      cor3: value.product.product_trimbox.Cor3,
      maq2: value.product.product_trimbox.Maquina2,
      maq3: value.product.product_trimbox.Maquina3,
      maq4: value.product.product_trimbox.Maquina4,
      altura: value.product.product_trimbox.AlturaInterna,
      comprimento: value.product.product_trimbox.ComprimentoInterno,
      largura: value.product.product_trimbox.LarguraInterna,
      num_faca: value.product.product_trimbox.Arranjo
    }
  ))

  const currentOrders = {
    "Dados do Pedido": {
      "Seu pedido: ": order?.ped_seupedido,
      "Nº Pedido: ": order?.id?.[0],
      "Faturado por: ": order?.billing_company?.descr,
      "Vendedor: ": order?.representative?.descr,
    },
    "Pagamento": {
      "Variação: ": order?.ped_variacao,
      "Tipo de frete: ": order?.ped_tipo_frete,
      "Total: ": formatter(Number(order.total)).toDecimal(2),
      "Cond. Pagamento: ": order?.payment_condition?.descr,
    },
    "Datas": {
      "Emissão: ": formatter(order?.ped_data_emissao).toDayOnly(),
      "Entrega: ": formatter(order?.ped_data_recebimento).toDayOnly(),
      "Embarque: ": formatter(order?.ped_data_embarque).toDayOnly(),
    },
  }

  const customers = {
    "Cliente": [
      customerCompany,
      customerId,
      customerAddress,
    ],
    "Endereço": {
      "UF:": uf,
      "CEP:": cep,
      "Cidade:": customerAddress,
      "Bairro:": district,
      "Endereço:": street,
    },
    "Contatos": {
      "Telefone:": tell,
      "Contato:": contact,
    },
    "Observações": {
      "Obs:": order?.ped_obs,
    },
  }

  const products = (value) => ({
    "Produto": {
      "FE:": value.codigo,
      "Preço:": formatter(Number(value.preco)).toDecimal(2),
      "Desconto:": formatter(Number(value.desconto)).toDecimal(2),
      "Situação:": value.situacao,
      "Data produção:": value.data_prod,
    },
    "Quantidades": {
      "Vendido:": formatter(Number(value.qtd)).toDecimal(2),
      "Baixado:": formatter(Number(value.qtd_baixada)).toDecimal(2),
      "Cancelado:": formatter(Number(value.qtd_cancelada)).toDecimal(2),
    },
    "Descrição": {
      "Produto:": value.produto,
      "Tipo:": value.tipo,
      "Faca:": value.faca,
      "Cores:": `${value.cor1 || ''} ${value.cor2 || ''} ${value.cor3 || ''}`,
    },
    "Detalhes": {
      "Num. Facas:": value.num_faca,
      "Versão:": value.versao,
      "Peso:": formatter(Number(value.peso)).toDecimal(2),
      "Papelão:": value.papelao,
      "Med. Interna:": `${value.altura || '0'} x ${value.comprimento || '0'} x ${value.largura || '0'}`,
      "Roteiro:": `${value.maq2 || ''} ${value.maq3 || ''} ${value.maq4 || ''}`,
    }
  })

  const generateReportGrid = object => {
    if (typeof object === 'object') {

      const generateReportRow = object => {
        if (!(object && typeof object === 'object')) return object

        return Object.entries(object).map(([field, value], index) => (
          <div key={index} className='Rep-row'>
            <span className='repRowLabel'>{field}</span>
            <span className='Rep-row-value'>{generateReportRow(value)}</span>
          </div>
        ))
      }

      return Object.entries(object).map(([head, values], index) =>
        <Box key={index} className='Rep-section'>
          <div className='Rep-section-label'>{head}:</div>
          <div>
            {
              values instanceof Array
                ? values.map((value, index) => (
                  <div key={index} className='Rep-row'>
                    <span className='Rep-row-value'>{value}</span>
                  </div>
                ))
                : generateReportRow(values)
            }
          </div>
        </Box>

      )
    } else throw new Error('invalid parameter')
  }

  const downloadPDF = () => {
    const doc = html.current
    html2canvas(doc)
      .then((canvas) => {
        let imgWidth = 208
        let imgHeight = canvas.height * imgWidth / canvas.width
        const imgData = canvas.toDataURL('img/png')
        const pdf = new jsPDF('p', 'mm', 'a4')
        pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight)
        pdf.save(`${title}.pdf`)
      })
  }

  return (
    <>
      <Modal
        open={show}
        size='lg'
        title={title}
        subtitle={subtitle}
        onAction={handlePrint}
        current={order}
        onClose={onClose}
        fullWidth
        actionProps={{
          label: 'Imprimir',
          background: 'success'
        }}
        content={
          <ReportCOntainer className="report-container" ref={html}>
            {
              inFetching ?
                <Box display='flex' justifyContent='center' marginTop='5rem'>
                  <CircularProgress size={50} color='secondary' />
                </Box> :
                <Box className="root-container">
                  <Box className="header">
                    <ReportLogo show='true' className="img" />
                    <strong>Pedido de Venda: {order?.cod_pedido}</strong>
                  </Box>
                  <Box className="header">
                    <h6>Representante: {order?.representative?.descr}</h6>
                    Data de Emissão: {new Date().toLocaleDateString('pt-br')}
                  </Box>
                  <span className="span">{order?.billing_company?.descr.toUpperCase()}</span>
                  <Box className="data-order">
                    {generateReportGrid(currentOrders)}
                  </Box>
                  <span className="span">{items?.[0]?.billing_type?.descr}</span>
                  <Box className="data-customer">
                    {generateReportGrid(customers)}
                  </Box>
                  <h6>
                    <strong>Entregar: </strong>
                    {
                      order?.triangular_delivery_address
                        ? formatter(order?.cod_cliente_entrega).toCNPJOrCPF() + ' - ' + order?.triangular_delivery_address
                        : deliveryAddr
                    }
                  </h6>
                  <span className="span">{"DADOS DO PRODUTO"}</span>
                  <Box className="data-product">
                    {
                      mapToItems?.map((product, idx) => {
                        return <div key={idx} className="div-products">{generateReportGrid(products(product))}</div>
                      })
                    }
                  </Box>
                </Box>
            }
          </ReportCOntainer>
        }
        otherActions={
          <Button
            background='info'
            onClick={downloadPDF}
            label="Baixar PDF"
          />
        }
      />
      <Report
        title={title}
        data={html.current}
        print={print}
      />
    </>
  )
}

OrderReport.propTypes = {
  current: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

OrderReport.defaultProps = {
  inSaving: false
}