// --> LIBRARIES
import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
// --> APPLICATION SERVICES
import { formatter } from '../../helpers'
import {
  isAction,
  isFlag,
  isActionOrFlag,
  cellAlign,
  findByPath,
  showColumn,
  calculeTableDimensions,
  booleanToString,
  handleClasses
} from './utils'
// --> COMPONENTS
import {
  TableContainer,
  Table as StyledTable,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  ReportHeader,
  Avatar,
  Chip,
  Flag,
  useStyles,
  TableFooter
} from './styles'
import {
  Edit,
  Visibility,
  Delete as DeleteIcon,
  Check as CheckIcon,
  Print as PrintIcon,
} from '@material-ui/icons'
import {
  Typography,
  Box,
  useTheme,
  useMediaQuery,
  Tooltip
} from '@material-ui/core'
import Button from '../Button'
import SortLabel from '../SortLabel'
import Paginator from '../ApiPagination'

import Report from '../Report'
import useRepresentative from '../../hooks/use_representative'
import { ReportLogo } from '../company_logo'

export function Table(props) {

  const {
    size,
    columns,
    rows,
    header,
    footer,
    pagination,
    currentSort,
    onSort,
    flaggable,
    paginable,
    sortable,
    scrollable,
    filterable,
    onFilter,
    autoTable,
    showFooter,
    report,
    inPrint,
  } = props

  const tableContainer = useRef(null)
  const theme = useTheme()
  const classes = useStyles({ ...props })
  const smallMediaQuery = useMediaQuery(theme.breakpoints.down('xs'))

  const representative = useRepresentative()

  const [tableHeight] = calculeTableDimensions(smallMediaQuery)

  const handleCurrentRecord = (row, column) => {
    const record = 
      autoTable 
      ? findByPath(column.key, row, { ...column })
      : row[column.key] ?? ''

    return record
  }

  const tableColumns = columns.map((column, index) => {

    const { key, type, align, label, sortableColumn, classes } = column

    const show = showColumn(column, smallMediaQuery)

    const showSortLabel = sortable && (sortableColumn ?? true) && !isActionOrFlag(type)

    return show && (
      <TableCell
        key={index}
        scope='col'
        type={type}
        align={cellAlign(align)}
        action={booleanToString(isAction(type))}
        className={classes?.join(' ')}
      >
        {
          showSortLabel ?
            <SortLabel
              name={key}
              label={label}
              currentSort={currentSort || {}}
              onClick={onSort}
              onFilter={onFilter}
              filterable={filterable}
            />
            : <span className={column.hidden ? 'hidden' : ''}>{label}</span>
        }
      </TableCell>
    )
  })

  const tableRows = rows.map((record, index) => {
    const { success, error } = record
    return (
      <TableRow
        hover
        key={index}
        flag={booleanToString(flaggable)}
        success={booleanToString(success)}
        error={booleanToString(error)}
      >
        {
          columns.map((column, index) => {

            const {
              align,
              type,
              callback,
              disabled,
              flagOptions,
            } = column

            const currentRecord = handleCurrentRecord(record, column) 

            const flagged = currentRecord && (isFlag(type))
            const show = showColumn(column, smallMediaQuery)
            const currentClasses = handleClasses(column, classes)

            const handleCallBack = () => {
              if (typeof callback === 'function') {
                callback(record)
              }
            }

            const handleRecord = value => {
              const { label, color } = flagOptions || {}

              const { editable, deletable } = record

              const buttonProps = {
                type: 'icon',
                size: size === 'small' ? 'small' : 'large'
              }

              switch (type) {
                case 'date':
                  return formatter(value).toDayOnly()
                case 'company':
                  return formatter(value).toCNPJOrCPF()
                case 'decimal':
                  return formatter(Number(value)).toDecimal(2)
                case 'area':
                  return formatter(value).toM2()
                case 'peso': 
                  return formatter(value).toKg()
                case 'edit':
                  return (
                    <Button
                      label={editable ? 'Editar' : 'Visualizar'}
                      icon={editable ? <Edit /> : <Visibility />}
                      onClick={callback(record, editable)}
                      disabled={disabled}
                      {...buttonProps}
                    />
                  )
                case 'remove':
                  return (deletable ?? editable) && (
                    <Button
                      label='Excluir'
                      background='error'
                      icon={<DeleteIcon />}
                      onClick={callback(record)}
                      disabled={disabled}
                      {...buttonProps}
                    />
                  )
                case 'print':
                  return (
                    <Button
                      label='Imprimir'
                      background='error'
                      icon={<PrintIcon />}
                      onClick={callback(record)}
                      disabled={error || disabled}
                      {...buttonProps}
                    />
                  )
                case 'chip':
                  const isConditionalChip = typeof label === 'object'
                  const chipIndex = value ? 0 : 1

                  const chipLabel = isConditionalChip ? label[chipIndex] : label
                  const chipBackground = isConditionalChip ? color[chipIndex] : color

                  const show = isConditionalChip || value

                  return show && (
                    <Chip
                      background={chipBackground}
                      label={chipLabel}
                      avatar={
                        <Avatar>
                          <CheckIcon />
                        </Avatar>
                      }
                    />
                  )
                case 'flag':
                  return (
                    <Tooltip arrow title={label}>
                      <Flag flagcolor={color} show={`${value}`} mark={`${value}`} />
                    </Tooltip>
                  )
                default:
                  return value
              }
            }

            return show && (
              <TableCell
                key={index}
                scope='row'
                type={type}
                flagged={booleanToString(flagged)}
                action={booleanToString(isAction(type))}
                align={cellAlign(align)}
                className={currentClasses}
                onClick={handleCallBack}
              >
                {handleRecord(currentRecord)}
              </TableCell>
            )
          })
        }
      </TableRow>
    )
  })



  return (
    <div className={classes.root}>
      {
        rows.length > 0 ?
          <TableContainer id={props.id} ref={tableContainer} window_inner_height={scrollable ? tableHeight : 'auto'} scroll={booleanToString(scrollable)}>
            <StyledTable size={size}>
              <TableHead>
                {
                  report &&
                  <TableRow className={clsx('report-header', { hidden: !report.show })}>
                    <TableCell className='report-root' colSpan={columns.length}>
                      <ReportHeader className="report-content">
                        <Box className='report-section'>
                          <Box height={100} className='report-row'>
                          <span>
                              <ReportLogo show='true'/>
                            </span>
                            <span className='report-title'>
                              {report.title}
                            </span>
                          </Box>
                          <Box className='report-row'>
                            <span>
                              <b>Representante:</b>{`${representative.emp_razao_social} ( ${formatter(representative.id).toCNPJOrCPF()} )`}
                            </span>
                            <span>
                              <b>Data de emissão:</b>{new Date().toLocaleDateString('pt-br')}
                            </span>
                          </Box>
                        </Box>
                      </ReportHeader>
                    </TableCell>
                  </TableRow>
                }
                {
                  header &&
                  <TableRow className='report-root'>
                    <TableCell colSpan={columns.length}>
                      {header}
                    </TableCell>
                  </TableRow>
                }
                <TableRow className={scrollable ? 'sticky-header' : 'normal-header'}>
                  {tableColumns}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableRows}
              </TableBody>
              {
                showFooter &&
                <TableFooter style={{ display: 'table-row-group' }}>
                  {
                    footer &&
                    <TableRow className='report-root'>
                      <TableCell colSpan={columns.length}>
                        {footer}
                      </TableCell>
                    </TableRow>
                  }
                </TableFooter>
              }
            </StyledTable>
          </TableContainer> :
          <Box className={classes.tableEmptyBox}>
            <Typography component='div' variant='body1' color='primary'>
              Nenhum registro encontrado
            </Typography>
          </Box>
      }
      {paginable && <Paginator pagination={pagination} />}
      {
        tableContainer &&
        <Report
          title={report?.title}
          data={tableContainer.current}
          print={inPrint}
        />
      }
    </div>
  )
}

Table.propTypes = {
  size: PropTypes.oneOf(['small', 'medium']),
  rows: PropTypes.array.isRequired,
  pagination: PropTypes.object,
  onSort: PropTypes.func,
  onFilter: PropTypes.func,
  flaggable: PropTypes.bool,
  paginable: PropTypes.bool,
  sortable: PropTypes.bool,
  scrollable: PropTypes.bool,
  filterable: PropTypes.bool,
  autoTable: PropTypes.bool,
  showFooter: PropTypes.bool,
  inPrint: PropTypes.oneOf([true, false, null]),
  report: PropTypes.shape({
    title: PropTypes.string,
    filters: PropTypes.object,
    show: PropTypes.bool,
  }),
  header: PropTypes.element,
  footer: PropTypes.element,

  columns: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    label: PropTypes.string, 
    callback: PropTypes.func, 
    showSm: PropTypes.bool,
    type: PropTypes.string,
    flagOptions: PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
      size: PropTypes.oneOf(['small', 'large']),
      color: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    })
  })),

  currentSort: PropTypes.shape({
    field: PropTypes.string,
    orderAsc: PropTypes.bool
  })
}

Table.defaultProps = {
  size: 'small',
  columns: [],
  rows: [],
  pagination: {},

  sortable: false,
  flaggable: false,
  paginable: true,
  scrollable: true,
  filterable: false,
  autoTable: false,
  inPrint: null,
  showFooter: false,
}

export default Table