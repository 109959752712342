import React from 'react'
import PropTypes from 'prop-types'
import { FormControlLabel, Switch as MuiSwitch } from '@material-ui/core'

export function Switch({
  label,
  checked,
  handleChange
}) {

  return (
    <FormControlLabel
      control={<MuiSwitch
        checked={checked}
        onChange={handleChange} />}
      label={label}
    />
  )
}

Switch.propTypes = {
  checked: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  label: PropTypes.string,
}

Switch.defaultProps = {
  label: '',
  checked: false,
}

export default Switch