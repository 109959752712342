import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import axios from '../services/api'
import { handleAjaxError } from '../helpers/helpers'

import Button from './Button'
import { CircularProgress } from '@material-ui/core'
import { Replay } from '@material-ui/icons'

export const AjaxLoader = ({
  url,
  params,
  onFetch,
  onCatch,
  visible,
  size,
  refreshIntervalInSeconds,
  visibleInLoading,
  reload,
  dependencies,
}) => {

  const DONE = 100
  const [progress, setProgress] = useState(0)

  const depArr = dependencies || []

  useEffect(() => {

    let timerId

    loadAjax()

    if (refreshIntervalInSeconds > 0) timerId = setInterval(() => loadAjax(), refreshIntervalInSeconds * 1000);

    return () => {
      if (timerId) clearInterval(timerId)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, reload, ...depArr])

  const handleCatch = error => {
    if (error && typeof onCatch === 'function') {
      onCatch()
    }
    throw error
  }

  const loadAjax = () => {
    setProgress(0)
    const requestConfig = { params: params }

    axios
      .get(url, requestConfig)
      .then(onFetch)
      .then(() => {
        setProgress(prevProgress => (prevProgress >= 100 ? 0 : prevProgress + 10))
      })
      .catch(handleCatch)
      .catch(handleAjaxError)
      .finally(() => setProgress(DONE))
  }

  return (
    visible && (
      progress !== DONE ?
        <CircularProgress
          color='secondary'
          size={size === 'small' ? 25 : 40}
        />
        :
        !visibleInLoading &&
        <Button
          type='icon'
          label='Recarregar'
          size={size || 'medium'}
          icon={<Replay />}
          onClick={loadAjax}
        />
    )
  )
}

// https://blog.logrocket.com/validating-react-component-props-with-prop-types-ef14b29963fc/
AjaxLoader.propTypes = {
  dependencies: PropTypes.array,
  onFetch: PropTypes.func.isRequired,
  onCatch: PropTypes.func,
  params: PropTypes.object,
  refreshIntervalInSeconds: PropTypes.number,
  size: PropTypes.string,
  url: PropTypes.string.isRequired,
  visible: PropTypes.bool,
  visibleInLoading: PropTypes.bool,
}

AjaxLoader.defaultProps = {
  refreshIntervalInSeconds: 0,
  visible: true,
  visibleInLoading: false,
  params: {}
}

export default AjaxLoader
