import { createMuiTheme } from '@material-ui/core/styles'
import { lighten, darken } from 'polished'
import { ptBR } from '@material-ui/core/locale'

const mainColor = '#d3411c'

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#1C1A1A',
      dark: '#000000',
      white: '#fff'
    },
    secondary: {
      main: mainColor,
      light: '#FFF',
      hover: lighten(0.2, mainColor),
      dark: darken(0.07, mainColor),
      lighter: lighten(0.5, mainColor),
    },
    transtitions: {
      default: '0.25s',
    },
    background: {
      paper: '#FFFFFF',
      default: '#ecf0f5',
    },
  },
  shape: {
    borderRadius: 1,
  },

  typography: {
    fontFamily: [
      'Roboto Slab',
      'Roboto',
      'sans-serif'
    ].join(','),
    button: {
      textTransform: 'none',
    },
  },
}, ptBR)

export default theme
