import React, { useEffect, useState } from 'react'
//Services and Configs
import { isEmpty, isNil } from 'ramda'
import API from '../../services/api_base'
import axiosBase from '../../services/api'
import { storagekeys } from '../../services/storage'
//Helpers and Hooks
import { useHistory } from 'react-router-dom'
import OrderForm from './form'
import usePagination from '../../hooks/use_pagination'
import { handleAjaxError, handleUnprocessableEntity, sortByField, checksIfDateIsLessThanTheCurrent, addDays } from '../../helpers'
//Components
import OrderReport from './orderReport'
import { Add, Search, Print } from '@material-ui/icons'
import { ContentPage, OrdersPage, HeaderPage, SearchButton } from './styles'
import { Box, ListItemIcon, ListItemText, useMediaQuery, useTheme } from '@material-ui/core'
import { AjaxLoader, changeSort, TablePagination, Table, Filters, Button, Modal } from '../../components'


export function Orders() {
  const theme = useTheme()
  const DEFAULT_ORDER_SCOPE = 'unfulfilled'

  const SCOPES = [
    { value: 'unfulfilled', label: 'Abertos' },
    { value: 'canceled', label: 'Cancelados' },
    { value: 'fulfilled', label: 'Baixados' },
  ]


  const findScopeLabel = scope =>
    SCOPES.find(s => s.value === scope)?.label

  const DEFAULT_SORT = {
    field: 'cod_pedido',
    orderAsc: false,
  }

  const history = useHistory()
  const smallDeviseMedia = useMediaQuery(`(max-width: 600px)`)

  const [orders, setOrders] = useState([])
  const [activeFilters, setActiveFilters] = useState({})
  const [currentSort, setCurrentSort] = useState(DEFAULT_SORT)
  const [currentOrder, setCurrentOrder] = useState({})
  const [formConfig, setFormConfig] = useState({
    url: '',
    isEditable: false
  })
  const [loader, setLoader] = useState(null)
  const [inProcessing, setInProcessing] = useState(false)
  const [showFilters, setShowFilters] = useState(false)
  const [showForm, setShowForm] = useState(false)
  const [showModalAlert, setShowModalAlert] = useState(false)
  const [inPrint, setInPrint] = useState(null)
  const [showOrder, setShowOrder] = useState(false)

  const {
    currentPage,
    recordsPerPage,
    changeRecordsPerPage: handleRowsPerPageChange,
    gotoPage: handlePageChange,
    gotoFirstPage,
    calculateNewPaginatorData,
  } = usePagination({ initialPage: parseInt(localStorage.getItem(storagekeys.actualPage)) || 0 })

  const { currentPageRecords } = calculateNewPaginatorData(orders)

  const handleShowOrder = () => setShowOrder(state => !state)

  useEffect(() => {
    localStorage.setItem(storagekeys.actualPage, currentPage)
  }, [currentPage])

  useEffect(() => {

    const setStorageFilters = strFilters => {
      const objectFilters = JSON.parse(strFilters)
      const filters = { ...objectFilters }
      setActiveFilters(filters)
      if (!isEmpty(filters)) setShowFilters(true)
    }

    if (loader === null) {
      const strFilters = localStorage.getItem(storagekeys.requestFilters)
      setStorageFilters(strFilters)

      setLoader(true)
    } else {
      const stringFilters = JSON.stringify(activeFilters)
      localStorage.setItem(storagekeys.requestFilters, stringFilters)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loader])

  const forcedLoader = () => setLoader(state => !state)

  const handlePrint = () => setInPrint(state => !state)

  const handleProcessing = () => setInProcessing(state => !state)

  const handleNewOrder = () => {
    setCurrentSort(DEFAULT_SORT)
    handleFiltering({})

    forcedLoader()
  }

  const handleSortChange = (field) => {
    changeSort(field, currentSort, setCurrentSort, setOrders)
  }

  const handleFiltering = filters => {
    setActiveFilters(filters)
    forcedLoader()
    gotoFirstPage()
  }

  const handleStateModalAlert = () => setShowModalAlert(state => !state)

  const treatedIndexParameters = () => {

    const treatedFilters = { ...activeFilters }

    // the scope of requests must be sent at the root of the object that contains the parameters
    delete treatedFilters.order_scope

    const scope =
      (activeFilters && activeFilters.order_scope)
      || DEFAULT_ORDER_SCOPE

    return {
      filters: { ...treatedFilters },
      order_scope: scope,
    }
  }

  const ordersIndexParameters = treatedIndexParameters()

  const handleDisplayFormForEdit = (record, isEditable) => (_event) => {
    setFormConfig({ url: record._links.self_for_edit, isEditable })
    setShowForm(true)
  }

  const handleDisplayFormForAdd = (_event) => {
    setFormConfig({ url: '', isEditable: true })
    setShowForm(true)
  }

  const handleCloseForm = (_event) => setShowForm(false)

  const handleSaveForm = (form) => {
    handleProcessing()
    form.setErrors({})
    var message = ''

    const { ped_data_recebimento: receiptDate, customer, cod_cliente_entrega, delivery_address, ped_dias_transito } = form.values

    const today = new Date(new Date().toDateString())
    const dateOffset = 3 + ped_dias_transito
    const availableDate = new Date(Date.parse(addDays(new Date(today), dateOffset))).toLocaleDateString('pt-br')

    if (checksIfDateIsLessThanTheCurrent(receiptDate, dateOffset)) {
      message = `Data informada não pode ser menor que ${availableDate}`
    }
    if (message !== '') {
      form.setErrors({
        ...form.errors,
        ped_data_recebimento: message
      })
      handleProcessing()

      return
    }

    if (customer.cli_triangular && !cod_cliente_entrega) {
      message = `Necessário informar o Cliente de Entrega na Venda Triangular`

      form.setErrors({
        ...form.errors,
        customers_delivery_to: message
      })
      handleProcessing()

      return

    }

    if (!cod_cliente_entrega && (delivery_address.id === null)) {
      message = `Necessário informar o endereço de entrega.`

      form.setErrors({
        ...form.errors,
        delivery_address: message
      })
      handleProcessing()

      return

    }

    if (form.values.id) {
      _updateOrder(form)
    } else {
      _createOrder(form)
    }
  }

  const _createOrder = (form) => {
    const { values: order } = form
    axiosBase
      .post(API.orders, { order })
      .then((response) => response.data)
      .then((newOrder) => {
        handleCloseForm()

        setOrders((previousRecords) => {
          const newRecords = previousRecords.concat(newOrder)

          return sortByField(
            newRecords,
            currentSort.field,
            currentSort.orderAsc
          )
        })
      })
      .then(handleNewOrder)
      .catch(handleUnprocessableEntity(form))
      .catch(handleAjaxError)
      .finally(handleProcessing)
  }

  const _updateOrder = (form) => {
    const { values: order } = form

    axiosBase
      .put([API.orders, order.id.toString()].join('/'), { order })
      .then((response) => response.data)
      .then((updatedOrder) => {
        handleCloseForm()
        setOrders(previousRecords => previousRecords.map(record =>
          // Notice the ids are actually arrays, so we must convert them into strings for comparison.
          record.id.toString() !== order.id.toString() ? record : updatedOrder
        ))
      })
      .then(forcedLoader)
      .catch(handleUnprocessableEntity(form))
      .catch(handleAjaxError)
      .finally(handleProcessing)
  }

  const _deleteOrder = currentRecord => {
    handleProcessing()
    axiosBase
      .delete([API.orders, currentRecord.id].join('/'))
      .then(() =>
        setOrders(previousOrders => previousOrders.filter(order =>
          order.id !== currentRecord.id ? order : false
        ))
      )
      .then(forcedLoader)
      .then(handleStateModalAlert)
      .catch(handleAjaxError)
      .finally(handleProcessing)
  }

  const handleDestroyCurrentOrder = record => _event => {
    handleStateModalAlert()
    setCurrentOrder(record)
  }

  const handleShowItems = (record) => {
    const url = `/orders/${record.id}/items`
    history.push(url)
  }

  const handleShowOrderReport = (record) => (_event) => {
    handleShowOrder()
    setCurrentOrder(record)
  }

  const flagType = 'chip'

  const config = [
    { key: 'cod_pedido', label: 'Pedido', callback: handleShowItems, showSm: true },
    { key: 'ped_data_emissao', label: 'Emissão', type: 'date' },
    { key: 'customer.id', label: 'CNPJ', type: 'company' },
    { key: 'customer.emp_razao_social', label: 'Cliente', showSm: true },
    { key: 'ped_seupedido', label: 'Seu Pedido' },
    { key: 'ped_data_recebimento', label: 'Recebimento', type: 'date' },

    { type: 'edit', callback: handleDisplayFormForEdit, showSm: true },
    { type: 'remove', callback: handleDestroyCurrentOrder, showSm: true },
    { type: 'print', callback: handleShowOrderReport },

    {
      key: 'Approved', type: flagType,
      flagOptions: { label: ['Aprovado', 'Em Análise'], size: 'small', color: [theme.palette.success.main, theme.palette.info.main] }
    },
  ]

  const records = currentPageRecords.map((currentOrder) => {

    const { customer, total_quantity: totalQuantity, ped_aprovado: isApproved } = currentOrder
    const editable = !(Math.ceil(totalQuantity) > 0 || isApproved === 'approved')

    return {
      ...currentOrder,
      'customer.id': customer?.id,
      'customer.emp_razao_social': customer?.emp_razao_social,
      editable,
      success: !editable,
      error: Math.ceil(totalQuantity) === 0,
      Approved: isApproved === 'approved',
    }
  })

  const orderIndexDependences = [
    activeFilters
  ]

  return (
    <ContentPage>
      <Filters
        open={showFilters}
        handleFiltering={handleFiltering}
        activeFilters={activeFilters}
      />
      <OrdersPage>
        <HeaderPage>
          <Box>
            <SearchButton
              button
              media={smallDeviseMedia ? 'sm' : 'md'}
              onClick={() => setShowFilters(state => !state)}>
              <ListItemIcon style={smallDeviseMedia ? { display: 'none' } : {}} className='list-icon'>
                <Search className='search' />
              </ListItemIcon>
              <ListItemText primary='Pesquisar' />
            </SearchButton>
          </Box>
          <Box>
            {!smallDeviseMedia ?
              <Button
                style={{ marginRight: '5px' }}
                type='icon'
                size='medium'
                label='Imprimir tabela'
                disabled={orders.length === 0 || inProcessing}
                onClick={handlePrint}
                icon={<Print />}
              /> : ""
            }
            {!isNil(loader) &&
              <AjaxLoader
                url={API.orders}
                onFetch={response => setOrders(response.data)}
                params={ordersIndexParameters}
                dependencies={orderIndexDependences}
                forcedLoader={loader}
              />
            }
          </Box>
        </HeaderPage>
        <Table
          report={{
            title: `Pedidos Emitidos (${findScopeLabel(activeFilters.order_scope ?? DEFAULT_ORDER_SCOPE)})`
          }}
          columns={config}
          rows={records}
          config={config}
          onSort={handleSortChange}
          currentSort={currentSort}
          inPrint={inPrint}
          flagable={true}
          paginable={false}
          sortable={true}
          smallTable={true}
        />
        {
          orders.length > 0 &&
          <TablePagination
            count={orders.length}
            rowsPerPage={recordsPerPage}
            page={currentPage}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
          />
        }
        <Box className='table-actions'>
          <Box padding={1}>
            <Button
              label='Novo Pedido'
              background='success'
              onClick={handleDisplayFormForAdd}
              startIcon={<Add />}
            />
          </Box>
        </Box>
        <OrderForm
          recordConfig={formConfig}
          show={showForm}
          onClose={handleCloseForm}
          onSave={handleSaveForm}
          inSaving={inProcessing}
        />
        <OrderReport
          show={showOrder}
          current={currentOrder}
          onClose={handleShowOrder}
        />
        <Modal
          open={showModalAlert}
          onClose={handleStateModalAlert}
          onAction={_deleteOrder}
          currentProp='cod_pedido'
          current={currentOrder}
          type='PEDIDO'
          inAction={inProcessing}
          actionProps={{
            label: 'Excluir',
            background: 'info'
          }}
        />
      </OrdersPage>
    </ContentPage >
  )
}

Orders.propTypes = {}

Orders.defaultProps = {}

export * from './items'