import styled from 'styled-components'
import theme from '../../theme'
import { Form as LibForm } from '../../lib/form'
import { Box, Paper, ListItem, TableRow } from '@material-ui/core'

export const ContentPage = styled(Box)`
  display:flex;

  @media only screen and (max-width: 800px ) {
    flex-direction:column;
  }
`
export const OrdersPage = styled(Paper)`
  flex:1;
  overflow-x: overlay;

  .orders-table {
    overflow-x:auto;
    min-width: 600px;
  }
  .table-header {
    background-color: ${theme.palette.background.default};
  }

  .show-items{
    color: #58a7e6;
    &:hover {
      color: ${theme.palette.secondary.main};
      text-decoration:none;
    }
  }
`

export const Form = styled(LibForm)`
`

export const HeaderPage = styled(Box)`
  padding:10px 15px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  min-height:70px;
`

export const StyledTableRow = styled(TableRow)`
  border-left: ${props => props.flag === 'show' && '10px solid'};
  border-left-color: ${props => props.error === 'true' ? theme.palette.error.main : theme.palette.secondary.main};
  border-left-color: ${props => props.success === 'true' && theme.palette.success.main};
  height: 40px;
`

export const SearchButton = styled(ListItem)`
  border-radius:100px;
  color: ${theme.palette.secondary.light};
  background-color: ${theme.palette.secondary.main};
  box-shadow: ${theme.shadows[3]};
  transition: 0.25s;
  .list-icon {
    min-width: 30px;
  }
  .search {
    color: ${theme.palette.secondary.light};
  }
  &:hover {
    background-color: ${theme.palette.secondary.hover};
    box-shadow: ${theme.shadows[6]};
    transition: 0.25s;
  }
`

export const ReportCOntainer = styled.div`
  width: 100%;
  margin-top: -3rem;

  .report-container {
    width: 100%;
    margin-top: -3rem;
  }

  .root-container {
    display: flex;
    flex-direction: column;
    font-size: 15px;
    width: 100%;
    margin-top: 30px;

    h6{
      display: flex;
      padding: 10px;
      margin-left: 20px;

      strong {
        margin-right: 5px;
      }
    }
  }

  .header {
    display: flex; 
    justify-content: space-between; 
    align-items: center;
    padding: 4px;
    margin-right: 25px;

    @media (max-width: 780px) {
      display: flex;
      flex-direction: column;
      justify-content: space-around
    }
  }
  .img {
    margin-left: 25px;

    @media (max-width: 780px) {
      margin-left: 3.4rem
    }
  }
  .span {
    display: flex;
    justify-content: center;
    font-size: 20px;
    color: #fff;
    background-color: #808080;
  }
  .data-order {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 10px;
    width: 53%;

    @media (max-width: 1190px) {
      width: 68%;
    }
    @media (max-width: 920px) {
      width: 80%;
    }
  }
  .data-customer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 10px;
    width: 75%;

    @media (max-width: 1120px) {
      width: 100%;
    }
  }
  .data-product {
    display: flex;
    flex-direction: column;
    padding: 10px;
  }

  .div-products {
    display: flex;
    justify-content: space-around;
    padding: 10px 0px;
    width: 100%;

    @media (max-width: 920px) {
      display: flex;
      flex-direction: column;
    }
  }

  .Rep-section-label {
    font-weight: 600;
    font-size: 13px;
    margin-bottom: 10px;
  }
  .Rep-section {
    flex: 1,
  }
  .Rep-row {
    display: flex
  }
  .Rep-row-value {
    font-weight: 400
  }
  .repRowLabel {
    margin-right: 8px;
    font-weight: 600;
    max-width: 150px;
  }

  @media (max-width: 750px) {
    .data-customer {
      display: flex;
      flex-direction: column;
    }
    .data-order {
      display: flex;
      flex-direction: column;
    }
  }
`